// 默认图标的 Base64 编码
const DEFAULT_ICON = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNMTkgMjFINWEyIDIgMCAwIDEtMi0yVjVhMiAyIDAgMCAxIDItMmgxMWw1IDV2MTFhMiAyIDAgMCAxLTIgMnoiPjwvcGF0aD48cG9seWxpbmUgcG9pbnRzPSIxNyAyMSAxNyAxMyA3IDEzIDcgMjEiPjwvcG9seWxpbmU+PHBvbHlsaW5lIHBvaW50cz0iNyAzIDcgOCAxNSA4Ij48L3BvbHlsaW5lPjwvc3ZnPg==';

import { getIconFromCache, saveIconToCache, isIconCached } from './iconCache';
import axios from 'axios';
import { BASE_URL } from '@/api/config';

// 内存缓存
const memoryCache = new Map();

// 并发控制
const pendingRequests = new Map();
const MAX_CONCURRENT_REQUESTS = 5;
let currentRequests = 0;

// 获取网站图标的工具函数
export const getFaviconUrl = async (domain) => {
  try {
    // 1. 检查内存缓存
    if (memoryCache.has(domain)) {
      return memoryCache.get(domain);
    }

    // 2. 检查本地缓存
    if (isIconCached(domain)) {
      const cachedIcon = getIconFromCache(domain);
      memoryCache.set(domain, cachedIcon);
      return cachedIcon;
    }

    // 3. 检查是否已有相同的请求在进行中
    if (pendingRequests.has(domain)) {
      return pendingRequests.get(domain);
    }

    // 4. 并发控制
    if (currentRequests >= MAX_CONCURRENT_REQUESTS) {
      return DEFAULT_ICON;
    }

    // 5. 创建新请求
    currentRequests++;
    const requestPromise = (async () => {
      try {
        const proxyUrl = `${BASE_URL}/favicon/${encodeURIComponent(domain)}`;
        const response = await axios.get(proxyUrl, { 
          responseType: 'blob',
          withCredentials: false,
          timeout: 2000, // 增加超时时间到8秒
          headers: {
            'Accept': 'image/x-icon,image/png,image/*;q=0.8,*/*;q=0.5',
            'Cache-Control': 'max-age=31536000',
            'X-Custom-Header': 'vue-frontend'
          }
        });
        
        if (response.status === 200) {
          const blob = response.data;
          const reader = new FileReader();
          const result = await new Promise((resolve) => {
            reader.onloadend = () => {
              const base64data = reader.result;
              if (base64data.startsWith('data:')) {
                resolve(base64data);
              } else {
                resolve(DEFAULT_ICON);
              }
            };
            reader.onerror = () => resolve(DEFAULT_ICON);
            reader.readAsDataURL(blob);
          });

          // 保存到缓存中
          if (result !== DEFAULT_ICON) {
            memoryCache.set(domain, result);
            saveIconToCache(domain, result);
          }
          return result;
        }
      } catch (error) {
        if (error.code === 'ECONNABORTED') {
          console.warn('Favicon request timeout for domain:', domain);
        } else {
          console.error('Failed to fetch favicon:', error);
        }
        return DEFAULT_ICON;
      } finally {
        currentRequests--;
        pendingRequests.delete(domain);
      }
      return DEFAULT_ICON;
    })();

    pendingRequests.set(domain, requestPromise);
    return requestPromise;
  } catch (error) {
    console.error('Unexpected error in getFaviconUrl:', error);
    return DEFAULT_ICON;
  }
};

// 批量预加载图标
export const preloadFavicons = async (domains) => {
  const uniqueDomains = [...new Set(domains)];
  return Promise.all(
    uniqueDomains.map(domain => getFaviconUrl(domain).catch(() => DEFAULT_ICON))
  );
}; 