import { createStore } from 'vuex'
import { state, apiService } from '@/api/service'

export default createStore({
  state,
  getters: {},
  mutations: {
    SET_ARTICLES(state, articles) {
      state.articles = articles
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_STATS(state, stats) {
      state.stats = stats
    },
    SET_CURRENT_ARTICLE(state, article) {
      state.currentArticle = article
    },
    SET_CURRENT_CATEGORY(state, category) {
      state.currentCategory = category
    },
    SET_CURRENT_TAG(state, tag) {
      state.currentTag = tag
    }
  },
  actions: {
    async fetchArticles({ commit }, { page = 1, page_size = 10 } = {}) {
      try {
        const response = await apiService.getArticles({ page, page_size })
        commit('SET_ARTICLES', response.data.results || [])
        return response.data
      } catch (error) {
        console.error('Error fetching articles:', error)
        throw error
      }
    },
    async fetchCategories({ commit }) {
      try {
        const response = await apiService.getCategories()
        commit('SET_CATEGORIES', response.data)
        return response.data
      } catch (error) {
        console.error('Error fetching categories:', error)
        throw error
      }
    },
    async fetchTags({ commit }) {
      try {
        const response = await apiService.getTags()
        commit('SET_TAGS', response.data)
        return response.data
      } catch (error) {
        console.error('Error fetching tags:', error)
        throw error
      }
    },
    async fetchStats({ commit }) {
      try {
        const response = await apiService.getBlogStats()
        commit('SET_STATS', response.data)
        return response.data
      } catch (error) {
        console.error('Error fetching blog stats:', error)
        throw error
      }
    },
    async fetchArticleById({ commit }, id) {
      try {
        const response = await apiService.getArticleById(id)
        commit('SET_CURRENT_ARTICLE', response.data)
        return response.data
      } catch (error) {
        console.error('Error fetching article:', error)
        throw error
      }
    },
    async fetchCategoryById({ commit }, id) {
      try {
        const response = await apiService.getCategoryById(id)
        commit('SET_CURRENT_CATEGORY', response.data)
        return response.data
      } catch (error) {
        console.error('Error fetching category:', error)
        throw error
      }
    },
    async fetchTagById({ commit }, id) {
      try {
        const response = await apiService.getTagById(id)
        commit('SET_CURRENT_TAG', response.data)
        return response.data
      } catch (error) {
        console.error('Error fetching tag:', error)
        throw error
      }
    }
  },
  modules: {}
}) 