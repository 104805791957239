<template>
  <div class="article-outline" v-if="headings.length > 0">
    <div class="outline-header">
      <el-icon><Document /></el-icon>
      文章目录
    </div>
    <div class="outline-content">
      <div
        v-for="(heading, index) in headings"
        :key="index"
        class="outline-item"
        :class="[
          `level-${heading.level}`,
          { active: currentHeading === heading.id }
        ]"
        @click="scrollToHeading(heading.id)"
      >
        {{ heading.text }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue'
import { Document } from '@element-plus/icons-vue'

const props = defineProps({
  contentRef: {
    type: Object,
    required: true
  }
})

const headings = ref([])
const currentHeading = ref('')

// 提取文章中的标题
const extractHeadings = () => {
  if (!props.contentRef) return
  
  const headingElements = props.contentRef.querySelectorAll('h1, h2, h3, h4, h5, h6')
  headings.value = Array.from(headingElements).map(el => {
    // 为每个标题添加 id
    if (!el.id) {
      el.id = `heading-${Math.random().toString(36).substr(2, 9)}`
    }
    return {
      id: el.id,
      text: el.textContent,
      level: parseInt(el.tagName.charAt(1)),
      top: el.offsetTop
    }
  })
}

// 滚动到指定标题
const scrollToHeading = (id) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

// 监听滚动，高亮当前标题
const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  
  // 找到当前滚动位置对应的标题
  for (let i = headings.value.length - 1; i >= 0; i--) {
    if (scrollTop >= headings.value[i].top - 100) {
      currentHeading.value = headings.value[i].id
      break
    }
  }
}

onMounted(() => {
  // 等待内容渲染完成后提取标题
  setTimeout(() => {
    extractHeadings()
    window.addEventListener('scroll', handleScroll)
  }, 500)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
.article-outline {
  position: fixed;
  left: 250px;
  top: 100px;
  width: 450px;
  /* max-height: calc(100vh - 120px); */
  height: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 100;
}

.outline-header {
  padding: 12px 16px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
}

.outline-content {
  padding: 8px 0;
}

.outline-item {
  padding: 6px 16px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  transition: all 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outline-item:hover {
  color: var(--el-color-primary);
  background: rgba(64, 158, 255, 0.1);
}

.outline-item.active {
  color: var(--el-color-primary);
  background: rgba(64, 158, 255, 0.1);
}

.level-1 { padding-left: 16px; font-weight: bold; }
.level-2 { padding-left: 32px; }
.level-3 { padding-left: 48px; }
.level-4 { padding-left: 64px; }
.level-5 { padding-left: 120px; }
.level-6 { padding-left: 96px; }

@media screen and (max-width: 1400px) {
  .article-outline {
    display: none;
  }
}
</style> 