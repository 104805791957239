<template>
  <page-container 
    :title="tag ? `${tag.name}` : '标签详情'" 
    :subtitle="tag ? `${tag.name} 标签下的文章` : '加载中...'"
  >
    <div class="tag-detail-container">
      <!-- 加载状态 -->
      <div v-if="loading" class="loading-state">
        <div class="loading-grid">
          <el-skeleton :rows="1" animated v-for="i in 3" :key="i">
            <template #template>
              <div class="loading-card">
                <div style="padding: 20px">
                  <el-skeleton-item variant="h3" style="width: 50%" />
                  <div style="margin-top: 16px">
                    <el-skeleton-item variant="text" style="width: 80%" />
                    <el-skeleton-item variant="text" style="width: 60%" />
                  </div>
                  <div style="margin-top: 16px; display: flex; gap: 12px">
                    <el-skeleton-item variant="button" style="width: 80px" />
                    <el-skeleton-item variant="button" style="width: 80px" />
                  </div>
                </div>
              </div>
            </template>
          </el-skeleton>
        </div>
      </div>

      <!-- 文章列表 -->
      <template v-else>
        <div v-if="articles.length > 0" class="articles-container">
          <article-list :articles="articles" />
          
          <!-- 分页器 -->
          <div class="pagination-container" v-if="total > 0">
            <el-pagination
              v-model:current-page="currentPage"
              v-model:page-size="pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
            />
          </div>
        </div>

        <!-- 空状态 -->
        <div v-else class="empty-state">
          <div class="empty-icon-wrapper">
            <el-icon class="empty-icon"><Document /></el-icon>
          </div>
          <div class="empty-text">
            <p>暂无相关文章</p>
            <p class="sub-text">该标签下还没有发布任何文章</p>
          </div>
          <el-button type="primary" @click="router.push('/tags')" class="back-btn">
            返回标签云
          </el-button>
        </div>
      </template>
    </div>
  </page-container>
</template>

<script>
export default {
  name: 'TagDetailPage'
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Document } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import PageContainer from '@/components/PageContainer.vue'
import ArticleList from '@/components/ArticleList.vue'
import apiService from '@/api/service'

const route = useRoute()
const router = useRouter()
const tag = ref(null)
const articles = ref([])
const loading = ref(true)
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(0)

// 获取标签详情
const fetchTagDetail = async () => {
  try {
    const response = await apiService.getTagById(route.params.id)
    tag.value = response.data
  } catch (error) {
    console.error('Error fetching tag:', error)
    ElMessage.error('获取标签信息失败')
    router.push('/tags')
  }
}

// 获取标签下的文章
const fetchArticles = async () => {
  try {
    loading.value = true
    const response = await apiService.getTagArticles(route.params.id, {
      page: currentPage.value,
      page_size: pageSize.value
    })
    articles.value = response.data.results
    total.value = response.data.count
  } catch (error) {
    console.error('Error fetching articles:', error)
    ElMessage.error('获取文章列表失败')
    articles.value = []
  } finally {
    loading.value = false
  }
}

// 处理页码变化
const handlePageChange = (page) => {
  currentPage.value = page
  fetchArticles()
}

// 处理每页条数变化
const handleSizeChange = (size) => {
  pageSize.value = size
  currentPage.value = 1
  fetchArticles()
}

onMounted(async () => {
  await fetchTagDetail()
  await fetchArticles()
})
</script>

<style lang="scss" scoped>
.tag-detail-container {
  min-height: calc(100vh - 200px);
  
  .loading-state {
    padding: 24px;
    background: var(--el-bg-color-overlay);
    border-radius: 12px;
    box-shadow: var(--el-box-shadow-lighter);
    
    .loading-grid {
      display: grid;
      gap: 24px;
      
      .loading-card {
        border-radius: 12px;
        overflow: hidden;
        background: var(--el-bg-color);
        box-shadow: var(--el-box-shadow-lighter);
      }
    }
  }

  .articles-container {
    background: var(--el-bg-color-overlay);
    border-radius: 12px;
    box-shadow: var(--el-box-shadow-lighter);
    overflow: hidden;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(
        90deg,
        var(--el-color-primary),
        var(--el-color-success)
      );
      opacity: 0.6;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    text-align: center;
    background: var(--el-bg-color-overlay);
    border-radius: 12px;
    box-shadow: var(--el-box-shadow-lighter);
    
    .empty-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      margin-bottom: 16px;
      border-radius: 50%;
      background: var(--el-color-primary-light-9);
      
      .empty-icon {
        font-size: 32px;
        color: var(--el-color-primary);
      }
    }
    
    .empty-text {
      margin-bottom: 24px;
      
      p {
        margin: 0;
        color: var(--el-text-color-primary);
        font-size: 16px;
        line-height: 1.6;
        
        &.sub-text {
          margin-top: 4px;
          color: var(--el-text-color-secondary);
          font-size: 14px;
        }
      }
    }
    
    .back-btn {
      min-width: 120px;
    }
  }

  .pagination-container {
    margin-top: 30px;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
}

// 响应式设计
@media (max-width: 768px) {
  .tag-detail-container {
    min-height: calc(100vh - 160px);
    
    .loading-state {
      padding: 16px;
      
      .loading-grid {
        gap: 16px;
      }
    }
    
    .empty-state {
      padding: 40px 16px;
      
      .empty-icon-wrapper {
        width: 60px;
        height: 60px;
        
        .empty-icon {
          font-size: 24px;
        }
      }
      
      .empty-text {
        margin-bottom: 20px;
        
        p {
          font-size: 14px;
          
          &.sub-text {
            font-size: 12px;
          }
        }
      }
    }

    .pagination-container {
      .el-pagination {
        font-size: 12px;
        
        .el-pagination__sizes {
          display: none;
        }
      }
    }
  }
}
</style> 