// API基础配置

// 判断是否是生产环境
const isProd = process.env.NODE_ENV === 'production'

// 根据环境设置基础URL
export const BASE_URL = isProd ? 'https://leazhi.com/api' : 'http://localhost:8000/api'

export const API_TIMEOUT = 60000

// XSS 防护函数
export const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

// 获取CSRF令牌
export const getCSRFToken = () => {
  const name = 'csrftoken';
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// API请求配置
export const API_CONFIG = {
  baseURL: BASE_URL,
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRFToken': getCSRFToken(),
    // 只在生产环境添加自定义头
    'X-Custom-Header': 'vue-frontend',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
}

// JWT Token管理
export const setToken = (token) => {
  localStorage.setItem('access_token', token);
}

export const getToken = () => {
  return localStorage.getItem('access_token');
}

export const removeToken = () => {
  localStorage.removeItem('access_token');
}

// 添加请求拦截器
export const setupAxiosInterceptors = (axios) => {
  axios.interceptors.request.use(
    config => {
      const token = getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        removeToken();
        // 可以在这里添加重定向到登录页面的逻辑
      }
      return Promise.reject(error);
    }
  );
}

// API端点配置
export const API_ENDPOINTS = {
  // 文章相关
  ARTICLES: '/articles/',
  ARTICLE_DETAIL: (id) => `/articles/${id}/`,
  ARTICLE_PASSWORD_VERIFY: (id) => `/articles/${id}/verify_password/`,
  ARTICLE_SEARCH: '/articles/search/',
  
  // 分类相关
  CATEGORIES: '/categories/',
  CATEGORY_DETAIL: (id) => `/categories/${id}/`,
  CATEGORY_ARTICLES: (id) => `/categories/${id}/articles/`,
  CATEGORY_MANAGE: '/categories/manage/',
  
  // // SSH资产相关
  // ASSET_CATEGORIES: '/asset-categories/',
  // ASSET_CATEGORY_DETAIL: (id) => `/asset-categories/${id}/`,
  // ASSET_CATEGORY_CHECK_PASSWORD: (id) => `/asset-categories/${id}/check-password/`,
  // ASSET_CATEGORY_VERIFY_PASSWORD: (id) => `/asset-categories/${id}/verify-password/`,
  // SSH_ASSETS: '/ssh-assets/',
  // SSH_ASSET_DETAIL: (id) => `/ssh-assets/${id}/`,
  
  // 标签相关
  TAGS: '/tags/',
  TAG_DETAIL: (id) => `/tags/${id}/`,
  TAG_ARTICLES: (id) => `/tags/${id}/articles/`,
  
  // 评论相关
  COMMENTS: '/comments/',
  ARTICLE_COMMENTS: (id) => `/comments/?article=${id}`,
  COMMENT_REPLIES: (id) => `/comments/${id}/replies/`,
  
  // 统计相关
  BLOG_STATS: '/blog-stats/',
  SYSTEM_STATUS: '/system-status/',
  STATS: '/stats/',
  
  // 照片相关
  PHOTO_CATEGORIES: '/photo-categories/',
  PHOTO_CATEGORY_DETAIL: (id) => `/photo-categories/${id}/`,
  PHOTOS: '/photos/',
  PHOTO_PASSWORD_VERIFY: (id) => `/photo-categories/${id}/verify_password/`,
  
  // 音乐相关
  MUSIC_CATEGORIES: '/music-categories/',
  MUSIC: '/music/',
  MUSIC_PASSWORD_VERIFY: (id) => `/music-categories/${id}/verify_password/`,
  
  // 软件相关
  SOFTWARE_CATEGORIES: '/software-categories/',
  SOFTWARE: '/software/',
  SOFTWARE_DOWNLOAD: (id) => `/software/${id}/download/`,
  SOFTWARE_PASSWORD_VERIFY: (id) => `/software-categories/${id}/verify_password/`,
  
  // 收藏相关
  FAVORITE_CATEGORIES: '/favorite-categories/',
  FAVORITES: '/favorites/',
  FAVORITE_PASSWORD_VERIFY: (id) => `/favorite-categories/${id}/verify_password/`,
  
  // 视频相关
  VIDEO_CATEGORIES: '/video-categories/',
  VIDEO: '/videos/',
  VIDEO_PASSWORD_VERIFY: (id) => `/video-categories/${id}/verify_password/`,
  
  // 其他
  ABOUT: '/about/',
  LOCATION: '/get-location/',
  CSRF: '/csrf/',
  UPLOAD: '/upload/'  // 用于文件上传
}

// 图片URL处理
export const getFullImageUrl = (url) => {
  if (!url) return ''
  if (url.startsWith('http')) return url
  // 如果是媒体文件路径，添加基础URL前缀
  if (url.startsWith('/media')) {
    return url.replace('/media', `${BASE_URL}/media`)
  }
  return `${BASE_URL}${url}`
}

// 音频URL处理
export const getFullAudioUrl = (url) => {
  if (!url) return ''
  return url.startsWith('http') ? url : `${BASE_URL}${url}`
} 