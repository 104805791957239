<template>
  <page-container title="相册详情" :back="true">
    <!-- 加载状态 -->
    <div v-if="loading" class="loading-state">
      <el-skeleton :rows="1" animated v-for="i in 8" :key="i">
        <template #template>
          <div class="loading-card">
            <el-skeleton-item variant="image" style="width: 100%; height: 200px;" />
            <div style="padding: 14px">
              <el-skeleton-item variant="h3" style="width: 50%" />
              <el-skeleton-item variant="text" style="margin-top: 8px; width: 80%" />
            </div>
          </div>
        </template>
      </el-skeleton>
    </div>

    <!-- 照片网格 -->
    <div v-else class="photos-grid">
      <div 
        v-for="photo in photos" 
        :key="photo.id"
        class="photo-card"
        :class="{ 'photo-card-enter': true }"
      >
        <div class="photo-wrapper">
          <el-image 
            :src="photo.image_url" 
            fit="cover"
            class="photo-image"
            :preview-src-list="previewImages"
            :initial-index="getPhotoIndex(photo)"
            preview-teleported
            loading="lazy"
            :zoom-rate="1.2"
            @load="handleImageLoad(photo)"
            :preview-options="{
              zoom: true,
              closeOnPressEscape: true,
              zoomRate: 1.2,
              defaultToolbar: true,
              mode: 'modal'
            }"
          >
            <template #error>
              <div class="image-error">
                <el-icon><Picture /></el-icon>
                <p>加载失败</p>
              </div>
            </template>
            <template #placeholder>
              <div class="image-loading">
                <el-icon class="is-loading"><Loading /></el-icon>
              </div>
            </template>
          </el-image>
        </div>
        
        <!-- 照片信息 -->
        <div class="photo-info">
          <h3 class="title">{{ photo.title }}</h3>
          <p class="description" v-if="photo.description">
            {{ photo.showFullDesc ? photo.description : truncateText(photo.description, 100) }}
            <span 
              v-if="photo.description && photo.description.length > 100" 
              class="show-more"
              @click.stop="toggleDescription(photo)"
            >
              {{ photo.showFullDesc ? '收起' : '展开' }}
            </span>
          </p>
          <div class="meta">
            <span class="date" v-if="photo.created_time">
              <el-icon><Calendar /></el-icon>
              {{ formatDate(photo.created_time) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <div v-if="!loading && total > pageSize" class="pagination-container">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :total="total"
        :page-sizes="[12, 24, 36, 48]"
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 空状态 -->
    <el-empty 
      v-if="!loading && photos.length === 0"
      class="empty-state"
      description="暂无照片"
    >
      <template #image>
        <el-icon class="empty-icon"><Picture /></el-icon>
      </template>
    </el-empty>
  </page-container>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { Picture, Loading, Calendar } from '@element-plus/icons-vue'
import PageContainer from '@/components/PageContainer.vue'
import dayjs from 'dayjs'
import apiService from '@/api/service'
import { getFullImageUrl } from '@/api/config'

const route = useRoute()
const photos = ref([])
const loading = ref(true)
const currentPage = ref(1)
const pageSize = ref(12)
const total = ref(0)

// 计算属性
const previewImages = computed(() => photos.value.map(photo => photo.image_url))

const formatDate = (date) => {
  if (!date) return ''
  return dayjs(date).format('YYYY年MM月DD日')
}

const truncateText = (text, length) => {
  if (!text) return ''
  return text.length > length ? text.slice(0, length) + '...' : text
}

const toggleDescription = (photo) => {
  photo.showFullDesc = !photo.showFullDesc
}

const getPhotoIndex = (photo) => {
  return photos.value.findIndex(p => p.id === photo.id)
}

const handleImageLoad = (photo) => {
  photo.loaded = true
}

const handleSizeChange = async (size) => {
  pageSize.value = size
  currentPage.value = 1
  await fetchPhotos()
}

const handleCurrentChange = async (page) => {
  currentPage.value = page
  await fetchPhotos()
}

const fetchPhotos = async () => {
  try {
    loading.value = true
    const response = await apiService.getPhotos({
      category: route.params.id,
      page: currentPage.value,
      page_size: pageSize.value
    })
    
    // 处理分页数据
    const photoData = response.data.results || response.data
    photos.value = Array.isArray(photoData) ? photoData.map(photo => ({
      ...photo,
      image_url: getFullImageUrl(photo.image),
      showFullDesc: false,
      loaded: false
    })) : []
    
    total.value = response.data.count || 0
  } catch (error) {
    console.error('Error fetching photos:', error)
    ElMessage.error('获取照片失败')
    photos.value = []
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchPhotos()
})
</script>

<style lang="scss" scoped>
.loading-state {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px;
  
  .loading-card {
    background: var(--el-bg-color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--el-box-shadow-lighter);
  }
}

.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  padding: 20px;
  
  .photo-card {
    background: var(--el-bg-color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--el-box-shadow-lighter);
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(20px);
    animation: cardEnter 0.3s ease forwards;
    
    &:hover {
      transform: translateY(-4px);
      box-shadow: var(--el-box-shadow-light);
      
      .photo-image {
        transform: scale(1.05);
      }
    }
    
    .photo-wrapper {
      position: relative;
      padding-bottom: 75%;
      overflow: hidden;
      
      .photo-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease;
      }
      
      .image-error,
      .image-loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--el-fill-color-light);
        color: var(--el-text-color-secondary);
        
        .el-icon {
          font-size: 32px;
          margin-bottom: 8px;
        }
        
        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }
    
    .photo-info {
      padding: 16px;
      background: var(--el-bg-color);
      
      .title {
        margin: 0 0 8px;
        font-size: 16px;
        font-weight: 500;
        color: var(--el-text-color-primary);
      }
      
      .description {
        margin: 0 0 12px;
        font-size: 14px;
        color: var(--el-text-color-regular);
        line-height: 1.6;
        
        .show-more {
          margin-left: 4px;
          color: var(--el-color-primary);
          cursor: pointer;
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
      
      .meta {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: var(--el-text-color-secondary);
        
        .date {
          display: flex;
          align-items: center;
          gap: 4px;
          
          .el-icon {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.empty-state {
  padding: 40px;
  text-align: center;
  
  .empty-icon {
    font-size: 48px;
    color: var(--el-color-primary);
  }
}

.pagination-container {
  margin-top: 20px;
  padding: 16px;
  display: flex;
  justify-content: center;
}

@keyframes cardEnter {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// 响应式设计
@media (max-width: 768px) {
  .photos-grid {
    gap: 16px;
    padding: 16px;
  }
}

@media (min-width: 1400px) {
  .photos-grid {
    gap: 32px;
    padding: 24px;
  }
}
</style>

<style lang="scss">
// 图片预览样式
.el-image-viewer__wrapper {
  .el-image-viewer__btn {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--el-color-primary) !important;
    border-radius: 50% !important;
    opacity: 0.9 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    
    &:hover {
      transform: scale(1.1) !important;
      background-color: var(--el-color-primary-light-3) !important;
      opacity: 1 !important;
    }
    
    i {
      font-size: 24px !important;
      color: #fff !important;
    }
  }
  
  .el-image-viewer__actions {
    padding: 12px 24px !important;
    background: rgba(0, 0, 0, 0.6) !important;
    border-radius: 24px !important;
    
    .el-image-viewer__actions__inner {
      gap: 16px !important;
      
      i {
        font-size: 24px !important;
        color: #fff !important;
        opacity: 0.9 !important;
        
        &:hover {
          opacity: 1 !important;
          transform: scale(1.1) !important;
        }
      }
    }
  }
  
  .el-image-viewer__close {
    width: 40px !important;
    height: 40px !important;
    top: 24px !important;
    right: 24px !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    border-radius: 50% !important;
    font-size: 24px !important;
    color: #fff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    
    &:hover {
      background-color: var(--el-color-primary) !important;
      transform: scale(1.1) rotate(90deg) !important;
    }
  }
}
</style> 