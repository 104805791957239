<template>
  <page-container title="我的博客" subtitle="分享技术，记录生活">
    <div class="home">
      <el-row :gutter="20">
        <el-col :span="24">
          <div v-if="loading" class="loading-state">
            <el-skeleton :rows="3" animated />
          </div>
          
          <template v-else>
            <div v-for="article in articles" :key="article.id" class="article-item">
              <el-card 
                class="article-card" 
                shadow="hover"
                :body-style="{ padding: '0px' }"
              >
                <div class="article-content" :style="getArticleStyle()">
                  <div class="article-main">
                    <div class="article-header">
                      <router-link 
                        :to="`/article/${article.id}`"
                        class="article-title"
                      >
                        {{ article.title }}
                        <el-icon v-if="article.is_protected" class="lock-icon"><Lock /></el-icon>
                      </router-link>
                      <div class="article-meta">
                        <span class="meta-item">
                          <el-icon><Calendar /></el-icon>
                          {{ formatDate(article.created_time) }}
                        </span>
                        <span class="meta-item">
                          <el-icon><View /></el-icon>
                          {{ article.views }} 阅读
                        </span>
                        <span class="meta-item">
                          <el-icon><Folder /></el-icon>
                          <template v-if="article.categories && article.categories.length > 0">
                            <router-link 
                              v-for="(category, index) in article.categories" 
                              :key="category.id" 
                              :to="`/category/${category.id}`"
                              class="category-link"
                            >
                              {{ category.name }}{{ index < article.categories.length - 1 ? ', ' : '' }}
                            </router-link>
                          </template>
                          <span v-else>未分类</span>
                        </span>
                      </div>
                    </div>
                    
                    <div class="article-excerpt">
                      {{ article.excerpt || '暂无摘要' }}
                    </div>
                    
                    <div class="article-footer">
                      <div class="article-tags">
                        <router-link
                          v-for="tag in article.tags"
                          :key="tag.id"
                          :to="`/tag/${tag.id}`"
                          class="tag-link"
                        >
                          # {{ tag.name }}
                        </router-link>
                      </div>
                      <el-button 
                        type="primary" 
                        text
                        class="read-more"
                        @click="$router.push(`/article/${article.id}`)"
                      >
                        阅读全文
                        <el-icon class="el-icon--right"><ArrowRight /></el-icon>
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
            
            <!-- 分页组件 -->
            <el-pagination
              v-if="totalItems > pageSize"
              v-model:current-page="currentPage"
              v-model:page-size="pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="totalItems"
              layout="total, sizes, prev, pager, next"
              class="pagination"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />

            <!-- 添加打赏区域 -->
            <div class="reward-section">
              <h3 class="reward-title">支持创作</h3>
              <p class="reward-description">如果这些内容对您有帮助，欢迎打赏支持！</p>
              <article-reward />
            </div>
          </template>
        </el-col>
      </el-row>
    </div>
  </page-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { Calendar, View, Folder, ArrowRight, Lock } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import PageContainer from '@/components/PageContainer.vue'
import ArticleReward from '@/components/Reward.vue'

const store = useStore()
const articles = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const totalItems = ref(0)
const totalPages = ref(0)
const loading = ref(true)

const formatDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

// 获取随机文章样式
const getArticleStyle = () => {
  const colors = [
    { border: '#91d5ff', bg: '#e6f7ff' }, // 蓝色系
    { border: '#b7eb8f', bg: '#f6ffed' }, // 绿色系
    { border: '#ffd591', bg: '#fff7e6' }, // 橙色系
    { border: '#ffadd2', bg: '#fff0f6' }, // 粉色系
    { border: '#d3adf7', bg: '#f9f0ff' }, // 紫色系
    { border: '#87e8de', bg: '#e6fffb' }, // 青色系
    { border: '#ffa39e', bg: '#fff1f0' }, // 红色系
    { border: '#9254de', bg: '#f9f0ff' }  // 深紫色系
  ]
  const style = colors[Math.floor(Math.random() * colors.length)]
  return {
    borderLeft: `4px solid ${style.border}`,
    backgroundColor: style.bg
  }
}

const fetchArticles = async () => {
  try {
    loading.value = true
    const response = await store.dispatch('fetchArticles', {
      page: currentPage.value,
      page_size: pageSize.value
    })
    
    articles.value = response.results
    totalItems.value = response.count
    totalPages.value = response.total_pages
  } catch (error) {
    console.error('Error fetching articles:', error)
    ElMessage.error('获取文章列表失败')
  } finally {
    loading.value = false
  }
}

const handleSizeChange = (val) => {
  pageSize.value = val
  currentPage.value = 1
  fetchArticles()
}

const handleCurrentChange = (val) => {
  currentPage.value = val
  fetchArticles()
}

onMounted(() => {
  fetchArticles()
})
</script>

<style lang="scss" scoped>
.home {
  .loading-state {
    padding: 40px;
    background: white;
    border-radius: 8px;
  }

  .article-item {
    margin-bottom: 24px;
    
    .article-card {
      transition: all 0.3s ease;
      border: none;
      overflow: hidden;
      border-radius: 8px;
      
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      }
      
      .article-content {
        padding: 24px;
        
        .article-main {
          .article-header {
            margin-bottom: 16px;
            
            .article-title {
              display: block;
              font-size: 20px;
              font-weight: 600;
              color: #303133;
              text-decoration: none;
              margin-bottom: 12px;
              transition: all 0.3s ease;
              
              &:hover {
                color: var(--el-color-primary);
              }
              
              .lock-icon {
                margin-left: 8px;
                font-size: 16px;
                color: #909399;
                vertical-align: middle;
              }
            }
            
            .article-meta {
              display: flex;
              flex-wrap: wrap;
              gap: 16px;
              color: #909399;
              font-size: 14px;
              
              .meta-item {
                display: inline-flex;
                align-items: center;
                gap: 4px;
                
                .el-icon {
                  font-size: 16px;
                }
              }
              
              .category-link {
                color: inherit;
                text-decoration: none;
                transition: color 0.3s ease;
                
                &:hover {
                  color: var(--el-color-primary);
                }
              }
            }
          }
          
          .article-excerpt {
            color: #606266;
            font-size: 15px;
            line-height: 1.8;
            margin: 16px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          
          .article-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            
            .article-tags {
              display: flex;
              flex-wrap: wrap;
              gap: 12px;
              
              .tag-link {
                color: #606266;
                text-decoration: none;
                font-size: 14px;
                transition: all 0.3s ease;
                background: rgba(255, 255, 255, 0.8);
                padding: 4px 8px;
                border-radius: 4px;
                
                &:hover {
                  color: var(--el-color-primary);
                  background: var(--el-color-primary-light-9);
                }
              }
            }
            
            .read-more {
              font-size: 14px;
              display: inline-flex;
              align-items: center;
              gap: 4px;
              padding: 8px 16px;
              border-radius: 4px;
              background: rgba(255, 255, 255, 0.8);
              
              .el-icon {
                transition: transform 0.3s ease;
              }
              
              &:hover {
                .el-icon {
                  transform: translateX(4px);
                }
              }
            }
          }
        }
      }
    }
  }
  
  .pagination-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .home {
    .article-item {
      .article-card {
        .article-content {
          .article-main {
            .article-meta {
              flex-direction: column;
              gap: 8px;
            }
            
            .article-footer {
              flex-direction: column;
              gap: 16px;
              align-items: flex-start;
              
              .article-tags {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }
}

/* 添加打赏区域样式 */
.reward-section {
  margin: 40px 0;  /* 移除左右边距，只保留上下边距 */
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 0;  /* 移除圆角 */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.reward-title {
  font-size: 20px;
  color: #303133;
  margin-bottom: 12px;
}

.reward-description {
  color: #606266;
  margin-bottom: 20px;
  font-size: 14px;
}
</style> 