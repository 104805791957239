<template>
  <div class="comment-list">
    <h3>评论列表 ({{ comments.length }})</h3>
    <div v-if="loading" class="loading">
      <el-skeleton :rows="3" animated />
    </div>
    <template v-else>
      <div v-if="comments.length > 0">
        <div v-for="comment in comments" :key="comment.id" class="comment-item">
          <div class="comment-header">
            <span class="nickname">{{ comment.nickname }}</span>
            <span class="time">{{ formatDate(comment.created_time) }}</span>
          </div>
          <div class="comment-content">{{ comment.content }}</div>
          <div class="comment-actions">
            <el-button 
              type="primary" 
              text 
              size="small"
              @click="showReplyForm(comment)"
            >
              回复
            </el-button>
          </div>
          
          <!-- 回复列表 -->
          <div v-if="comment.replies && comment.replies.length > 0" class="replies-list">
            <div v-for="reply in comment.replies" :key="reply.id" class="reply-item">
              <div class="reply-header">
                <span class="nickname">{{ reply.nickname }}</span>
                <span class="time">{{ formatDate(reply.created_time) }}</span>
              </div>
              <div class="reply-content">{{ reply.content }}</div>
              <div class="reply-actions">
                <el-button 
                  type="primary" 
                  text 
                  size="small"
                  @click="showReplyForm(comment, reply)"
                >
                  回复
                </el-button>
              </div>
            </div>
          </div>

          <!-- 回复表单 -->
          <div v-if="activeReply === comment.id" class="reply-form">
            <el-form 
              :ref="(el) => { if (el) replyFormRef = el }"
              :model="replyForm"
              :rules="replyRules"
              label-width="80px"
            >
              <div class="form-row">
                <el-form-item label="昵称" prop="nickname" class="nickname-item">
                  <el-input v-model="replyForm.nickname" placeholder="请输入昵称" />
                </el-form-item>
                
                <el-form-item label="邮箱" prop="email" class="email-item">
                  <el-input v-model="replyForm.email" placeholder="请输入邮箱" />
                </el-form-item>
              </div>
              
              <el-form-item label="回复内容" prop="content">
                <el-input
                  v-model="replyForm.content"
                  type="textarea"
                  :rows="3"
                  :placeholder="replyPlaceholder"
                />
              </el-form-item>
              
              <el-form-item>
                <el-button type="primary" @click="submitReply">
                  发表回复
                </el-button>
                <el-button @click="cancelReply">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <el-empty v-else description="暂无评论" />
    </template>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import apiService from '@/api/service'

export default {
  name: 'CommentList',
  props: {
    articleId: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    const comments = ref([])
    const loading = ref(true)
    const activeReply = ref(null)
    const replyFormRef = ref(null)
    const replyingTo = ref(null)
    
    const replyForm = ref({
      nickname: '',
      email: '',
      content: ''
    })
    
    const replyRules = {
      nickname: [
        { required: true, message: '请输入昵称', trigger: 'blur' },
        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
      ],
      email: [
        { required: true, message: '请输入邮箱', trigger: 'blur' },
        { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
      ],
      content: [
        { required: true, message: '请输入回复内容', trigger: 'blur' },
        { min: 5, message: '回复内容至少 5 个字符', trigger: 'blur' }
      ]
    }
    
    const replyPlaceholder = computed(() => {
      if (replyingTo.value) {
        return `回复 @${replyingTo.value.nickname}：`
      }
      return '请输入回复内容'
    })
    
    const fetchComments = async () => {
      try {
        loading.value = true
        console.log('Fetching comments for article:', props.articleId)
        const response = await apiService.getComments(props.articleId)
        console.log('Comments response:', response)
        
        if (Array.isArray(response.data)) {
          comments.value = response.data.map(comment => ({
            ...comment,
            created_time: comment.created_time || new Date().toISOString()
          }))
        } else {
          comments.value = []
          console.warn('Unexpected comments data format:', response.data)
        }
      } catch (error) {
        console.error('Error fetching comments:', error)
        comments.value = []
        if (error.response && error.response.status !== 404) {
          const message = error.response.data?.message || '获取评论列表失败'
          ElMessage.error(message)
        }
      } finally {
        loading.value = false
      }
    }
    
    const formatDate = (date) => {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    }
    
    const showReplyForm = (comment, reply = null) => {
      activeReply.value = comment.id
      replyingTo.value = reply || comment
      replyForm.value = {
        nickname: '',
        email: '',
        content: ''
      }
    }
    
    const cancelReply = () => {
      activeReply.value = null
      replyingTo.value = null
      replyForm.value = {
        nickname: '',
        email: '',
        content: ''
      }
    }
    
    const submitReply = async () => {
      if (!replyFormRef.value) {
        console.error('Form ref not found')
        return
      }
      
      try {
        const valid = await replyFormRef.value.validate()
        if (!valid) {
          return
        }
        
        const data = {
          article: props.articleId,
          content: replyForm.value.content.trim(),
          nickname: replyForm.value.nickname.trim(),
          email: replyForm.value.email.trim(),
          parent_id: activeReply.value
        }

        console.log('Submitting reply:', data)
        const response = await apiService.createComment(data)
        console.log('Reply submit response:', response)
        
        ElMessage.success('回复发布成功')
        cancelReply()
        await fetchComments()
      } catch (error) {
        console.error('Error submitting reply:', error)
        console.error('Error response:', error.response)
        console.error('Error data:', error.response?.data)
        
        if (error.response) {
          const message = error.response.data?.detail || error.response.data?.message || '回复发布失败'
          ElMessage.error(message)
        } else {
          ElMessage.error('回复发布失败，请稍后重试')
        }
      }
    }
    
    onMounted(() => {
      fetchComments()
    })
    
    return {
      comments,
      loading,
      formatDate,
      fetchComments,
      activeReply,
      replyForm,
      replyRules,
      replyFormRef,
      replyingTo,
      replyPlaceholder,
      showReplyForm,
      cancelReply,
      submitReply
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-list {
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  
  h3 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #303133;
  }
  
  .comment-item {
    padding: 15px 0;
    border-bottom: 1px solid #ebeef5;
    
    &:last-child {
      border-bottom: none;
    }
    
    .comment-header {
      margin-bottom: 8px;
      
      .nickname {
        font-weight: 500;
        color: #303133;
      }
      
      .time {
        margin-left: 10px;
        font-size: 12px;
        color: #909399;
      }
    }
    
    .comment-content {
      color: #606266;
      line-height: 1.6;
      margin-bottom: 8px;
    }

    .comment-actions {
      margin-bottom: 12px;
    }
    
    .replies-list {
      margin-left: 24px;
      padding-left: 16px;
      border-left: 2px solid #ebeef5;
      
      .reply-item {
        padding: 12px 0;
        
        &:not(:last-child) {
          border-bottom: 1px dashed #ebeef5;
        }
        
        .reply-header {
          margin-bottom: 8px;
          
          .nickname {
            font-weight: 500;
            color: #303133;
          }
          
          .time {
            margin-left: 10px;
            font-size: 12px;
            color: #909399;
          }
        }
        
        .reply-content {
          color: #606266;
          line-height: 1.6;
          margin-bottom: 8px;
        }
      }
    }
    
    .reply-form {
      margin-top: 16px;
      padding: 16px;
      background: #f8f9fa;
      border-radius: 4px;
      
      .form-row {
        display: flex;
        gap: 20px;
        margin-bottom: 18px;
        
        .nickname-item {
          flex: 1;
          margin-bottom: 0;
        }
        
        .email-item {
          flex: 2;
          margin-bottom: 0;
        }
      }
    }
  }
  
  .loading {
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  .comment-list {
    padding: 15px;
    
    .comment-item {
      .replies-list {
        margin-left: 12px;
        padding-left: 12px;
      }
      
      .reply-form {
        padding: 12px;
        
        .form-row {
          flex-direction: column;
          gap: 0;
          
          .nickname-item,
          .email-item {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}
</style> 