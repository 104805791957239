<template>
  <page-container title="我的相册" subtitle="记录生活中的美好瞬间">
    <!-- 加载状态 -->
    <div v-if="loading" class="loading-grid">
      <el-skeleton :rows="1" animated v-for="i in 6" :key="i">
        <template #template>
          <div class="loading-card">
            <el-skeleton-item variant="image" style="width: 100%; height: 200px;" />
            <div style="padding: 14px">
              <el-skeleton-item variant="h3" style="width: 50%" />
              <div style="display: flex; justify-content: space-between; margin-top: 10px">
                <el-skeleton-item variant="text" style="width: 40%" />
                <el-skeleton-item variant="text" style="width: 40%" />
              </div>
            </div>
          </div>
        </template>
      </el-skeleton>
    </div>

    <!-- 空状态 -->
    <el-empty
      v-else-if="categories.length === 0"
      class="empty-state"
    >
      <template #image>
        <div class="empty-icon-wrapper">
          <el-icon class="empty-icon"><Picture /></el-icon>
        </div>
      </template>
      <template #description>
        <div class="empty-text">
          <p>暂无相册内容</p>
          <p class="sub-text">快来添加你的第一个相册吧</p>
        </div>
      </template>
    </el-empty>

    <!-- 相册列表 -->
    <transition-group 
      v-else
      name="album-list" 
      tag="div" 
      class="albums-grid"
    >
      <div
        v-for="category in categories"
        :key="category.id"
        class="album-card"
        @click="viewAlbum(category)"
      >
        <div class="album-cover" :class="{ 'is-protected': category.is_protected }">
          <el-image 
            :src="category.cover_image" 
            fit="cover"
            class="album-image"
            loading="lazy"
          >
            <template #error>
              <div class="image-slot">
                <el-icon><Picture /></el-icon>
                <p>暂无图片</p>
              </div>
            </template>
            <template #placeholder>
              <div class="image-loading">
                <el-icon class="is-loading"><Loading /></el-icon>
              </div>
            </template>
          </el-image>
          
          <!-- 加密相册标记 -->
          <div v-if="category.is_protected" class="protected-badge">
            <el-icon><Lock /></el-icon>
            <span>加密相册</span>
          </div>
        </div>
        
        <div class="album-footer">
          <div class="album-header">
            <h3 class="album-title">{{ category.name }}</h3>
            <div class="basic-info">
              <span class="info-item">
                <el-icon><Picture /></el-icon>
                {{ category.photo_count }} 张
              </span>
              <span class="info-item">
                <el-icon><Calendar /></el-icon>
                {{ formatDate(category.created_time) }}
              </span>
            </div>
          </div>
          <div class="album-description" v-if="category.description">
            <p class="description-text" :class="{ 'is-expanded': expandedDescriptions.has(category.id) }">
              {{ category.description }}
            </p>
            <el-button 
              v-if="category.description.length > 20"
              link
              type="primary"
              size="small"
              @click.stop="toggleDescription(category.id)"
            >
              {{ expandedDescriptions.has(category.id) ? '收起' : '展开' }}
            </el-button>
          </div>
        </div>
      </div>
    </transition-group>

    <!-- 添加打赏区域 -->
    <div class="reward-section">
      <h3 class="reward-title">支持创作</h3>
      <p class="reward-description">如果这些内容对您有帮助，欢迎打赏支持！</p>
      <article-reward />
    </div>

    <!-- 密码验证对话框 -->
    <el-dialog
      v-model="passwordDialogVisible"
      title="相册密码验证"
      width="400px"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <div class="password-form">
        <el-input
          v-model="albumPassword"
          type="password"
          placeholder="请输入相册密码"
          show-password
          @keyup.enter="verifyPassword"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelPasswordDialog">取消</el-button>
          <el-button type="primary" @click="verifyPassword">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </page-container>
</template>

<script>
export default {
  name: 'PhotoAlbums'
}
</script>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Picture, Loading, Calendar, Lock } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import PageContainer from '@/components/PageContainer.vue'
import ArticleReward from '@/components/Reward.vue'
import apiService from '@/api/service'
import { getFullImageUrl } from '@/api/config'
import dayjs from 'dayjs'

const router = useRouter()
const loading = ref(true)
const categories = ref([])
const passwordDialogVisible = ref(false)
const albumPassword = ref('')
const currentCategory = ref(null)
const verifiedCategories = ref(new Set())
const expandedDescriptions = ref(new Set())

// 获取相册列表
const fetchCategories = async () => {
  try {
    loading.value = true
    const response = await apiService.getPhotoCategories()
    categories.value = response.data.map(category => ({
      ...category,
      cover_image: getFullImageUrl(category.cover_image)
    }))
  } catch (error) {
    console.error('Error fetching categories:', error)
    ElMessage.error('获取相册列表失败')
  } finally {
    loading.value = false
  }
}

// 查看相册
const viewAlbum = (category) => {
  if (category.is_protected) {
    currentCategory.value = category
    passwordDialogVisible.value = true
    albumPassword.value = ''
  } else {
    router.push(`/photos/${category.id}`)
  }
}

// 验证密码
const verifyPassword = async () => {
  if (!albumPassword.value) {
    ElMessage.warning('请输入密码')
    return
  }

  try {
    console.log('Sending password verification request:', {
      categoryId: currentCategory.value.id,
      password: albumPassword.value
    })

    const response = await apiService.verifyPhotoPassword(currentCategory.value.id, albumPassword.value)
    
    console.log('Password verification response:', response.data)

    if (response.data && response.data.status === 'success') {
      passwordDialogVisible.value = false
      verifiedCategories.value.add(currentCategory.value.id)
      router.push(`/photos/${currentCategory.value.id}`)
    } else {
      ElMessage.error(response.data?.message || '密码错误')
    }
  } catch (error) {
    console.error('Password verification error:', error)
    console.error('Error response:', error.response?.data)
    
    if (error.response) {
      switch (error.response.status) {
        case 400:
          ElMessage.error(error.response.data.message || '密码格式错误')
          break
        case 404:
          ElMessage.error('验证接口不存在，请检查API端点')
          break
        case 403:
          ElMessage.error('没有权限访问此相册')
          break
        default:
          ElMessage.error(error.response.data?.message || '密码错误')
      }
    } else if (error.request) {
      ElMessage.error('网络请求失败，请检查网络连接')
    } else {
      ElMessage.error('密码验证失败，请稍后重试')
    }
  }
}

// 取消密码验证
const cancelPasswordDialog = () => {
  passwordDialogVisible.value = false
  currentCategory.value = null
  albumPassword.value = ''
}

// 格式化日期
const formatDate = (date) => {
  if (!date) return ''
  return dayjs(date).format('YYYY年MM月DD日')
}

// 切换描述展开状态
const toggleDescription = (categoryId) => {
  if (expandedDescriptions.value.has(categoryId)) {
    expandedDescriptions.value.delete(categoryId)
  } else {
    expandedDescriptions.value.add(categoryId)
  }
}

onMounted(() => {
  fetchCategories()
})
</script>

<style lang="scss" scoped>
.loading-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: var(--el-bg-color);
  border-radius: 8px;
  
  .loading-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--el-box-shadow-lighter);
  }
}

.empty-state {
  padding: 40px;
  background-color: var(--el-bg-color);
  border-radius: 8px;
  
  .empty-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin: 0 auto 16px;
    border-radius: 50%;
    background: var(--el-color-primary-light-9);
    
    .empty-icon {
      font-size: 32px;
      color: var(--el-color-primary);
    }
  }
  
  .empty-text {
    p {
      margin: 0;
      color: var(--el-text-color-primary);
      font-size: 16px;
      line-height: 1.6;
      
      &.sub-text {
        margin-top: 4px;
        color: var(--el-text-color-secondary);
        font-size: 14px;
      }
    }
  }
}

.albums-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: var(--el-bg-color);
  border-radius: 8px;
  
  .album-card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: var(--el-bg-color);
    box-shadow: var(--el-box-shadow-lighter);
    transition: all 0.3s ease;
    cursor: pointer;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: var(--el-box-shadow-light);
    }
    
    .album-cover {
      position: relative;
      width: 100%;
      padding-bottom: 75%;
      overflow: hidden;
      border-radius: 8px 8px 0 0;

      .album-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .protected-badge {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 8px;
        background-color: rgba(255, 85, 0, 0.9);
        color: white;
        border-radius: 4px;
        font-size: 12px;
        opacity: 0.9;
        transition: opacity 0.3s;
        z-index: 1;
        
        .el-icon {
          font-size: 14px;
        }
      }

      &:hover .protected-badge {
        opacity: 1;
      }
    }
    
    .album-footer {
      padding: 16px;
      background: var(--el-bg-color);
      border-top: 1px solid var(--el-border-color-lighter);
      
      .album-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        
        .album-title {
          font-size: 16px;
          font-weight: 600;
          color: var(--el-text-color-primary);
          margin: 0;
          line-height: 1.4;
          flex: 1;
          margin-right: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        .basic-info {
          display: flex;
          align-items: center;
          gap: 12px;
          font-size: 13px;
          color: var(--el-text-color-secondary);
          white-space: nowrap;
          
          .info-item {
            display: flex;
            align-items: center;
            gap: 4px;
            
            .el-icon {
              font-size: 14px;
            }
          }
        }
      }
      
      .album-description {
        position: relative;
        
        .description-text {
          font-size: 14px;
          color: var(--el-text-color-secondary);
          margin: 0;
          line-height: 1.4;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          transition: all 0.3s ease;
          
          &.is-expanded {
            -webkit-line-clamp: unset;
          }
          
          &:not(.is-expanded) {
            max-height: 20px;
          }
        }
        
        .el-button {
          padding: 0;
          height: 20px;
          margin-left: 4px;
          font-size: 13px;
        }
      }
    }
  }
}

// 密码验证对话框
.password-form {
  padding: 20px 0;
}

// 动画
.album-list-enter-active,
.album-list-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.album-list-enter-from,
.album-list-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

// 响应式设计
@media (max-width: 768px) {
  .photo-container {
    .albums-grid {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      gap: 16px;
      padding: 16px;
      
      .album-card {
        .album-cover {
          .album-overlay {
            padding: 16px;
            
            .album-info {
              h3 {
                font-size: 18px;
                margin-bottom: 8px;
              }
              
              .meta {
                gap: 12px;
                font-size: 13px;
              }
            }
          }
        }
        .album-footer {
          .album-header {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            
            .album-title {
              margin-right: 0;
            }
            
            .basic-info {
              width: 100%;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .photo-container {
    .albums-grid {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      gap: 32px;
      padding: 32px;
    }
  }
}

/* 添加打赏区域样式 */
.reward-section {
  margin: 40px 0;  /* 移除左右边距，只保留上下边距 */
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 0;  /* 移除圆角 */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.reward-title {
  font-size: 20px;
  color: #303133;
  margin-bottom: 12px;
}

.reward-description {
  color: #606266;
  margin-bottom: 20px;
  font-size: 14px;
}
</style> 