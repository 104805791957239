<template>
  <div class="page-container">
    <div class="page-header" v-if="title">
      <h1>{{ title }}</h1>
      <p class="subtitle" v-if="subtitle">{{ subtitle }}</p>
    </div>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContainer',
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 20px;
  min-height: calc(100vh - 120px);
  background: #f8f9fa;

  .page-header {
    text-align: center;
    margin-bottom: 40px;

    h1 {
      font-size: 28px;
      color: #409EFF;
      margin: 0 0 8px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 16px;
      color: #909399;
      margin: 0;
    }
  }

  .page-content {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }
}
</style> 