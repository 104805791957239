import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import Category from '@/views/Category.vue'
import Music from '@/views/Music.vue'
import Favorite from '@/views/Favorite.vue'
import About from '@/views/About.vue'
import ArticleDetail from '@/views/ArticleDetail.vue'
import CategoryDetail from '@/views/CategoryDetail.vue'
import TagDetail from '@/views/TagDetail.vue'
import PhotoAlbums from '@/views/PhotoAlbums.vue'
import PhotoAlbumDetail from '@/views/PhotoAlbumDetail.vue'
import SearchResult from '@/views/SearchResult.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/search',
    name: 'search',
    component: SearchResult,
    meta: {
      title: '搜索结果'
    }
  },
  {
    path: '/category',
    name: 'Category',
    component: Category
  },
  {
    path: '/music',
    name: 'Music',
    component: Music
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: Favorite
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/article/:id',
    name: 'ArticleDetail',
    component: ArticleDetail,
    props: true
  },
  {
    path: '/category/:id',
    name: 'CategoryDetail',
    component: CategoryDetail,
    props: true
  },
  {
    path: '/tag/:id',
    name: 'TagDetail',
    component: TagDetail,
    props: true
  },
  {
    path: '/photos',
    name: 'PhotoAlbums',
    component: PhotoAlbums,
    meta: {
      title: '相册'
    }
  },
  {
    path: '/photos/:id',
    name: 'PhotoAlbumDetail',
    component: PhotoAlbumDetail,
    props: true
  },
  {
    path: '/software',
    name: 'SoftwarePage',
    component: () => import('../views/Software.vue')
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('@/views/Tags.vue'),
    meta: {
      title: '标签云'
    }
  },
  {
    path: '/tag',
    redirect: '/tags'
  },
  {
    path: '/videos',
    name: 'VideoList',
    component: () => import('@/views/VideoList.vue'),
    meta: {
      title: '视频'
    }
  },
  {
    path: '/videos/:id',
    name: 'VideoDetail',
    component: () => import('@/views/VideoDetail.vue'),
    meta: {
      title: '视频详情'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router 