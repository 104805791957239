<template>
  <div class="music-player" v-if="currentMusic">
    <div class="player-content">
      <div class="now-playing">
        <el-image 
          :key="currentMusic.id"
          :src="currentMusic.cover" 
          class="playing-cover"
          fit="cover"
        >
          <template #error>
            <div class="image-placeholder">
              <el-icon><Headset /></el-icon>
            </div>
          </template>
        </el-image>
        <div class="playing-info">
          <h4>{{ currentMusic?.title || '未知歌曲' }}</h4>
          <p>{{ currentMusic?.artist || '未知歌手' }}</p>
        </div>
      </div>

      <div class="player-controls">
        <el-button-group>
          <el-button @click="previousTrack">
            <el-icon><Back /></el-icon>
          </el-button>
          <el-button @click="togglePlay">
            <el-icon>
              <VideoPause v-if="isPlaying" />
              <CaretRight v-else />
            </el-icon>
          </el-button>
          <el-button @click="nextTrack">
            <el-icon><Right /></el-icon>
          </el-button>
        </el-button-group>
      </div>

      <div class="progress-bar">
        <span class="time">{{ formatTime(currentTime) }}</span>
        <el-slider
          v-model="currentTime"
          :max="duration"
        />
        <span class="time">{{ formatTime(duration) }}</span>
      </div>

      <div class="extra-controls">
        <el-button 
          class="control-btn"
          @click="togglePlayMode"
          :title="playModeTitle"
        >
          <el-icon>
            <component :is="playModeIcon" />
          </el-icon>
        </el-button>
        <el-button
          class="control-btn"
          @click="toggleLyrics"
          :class="{ active: showLyrics }"
          title="歌词"
        >
          <el-icon><Document /></el-icon>
        </el-button>
      </div>
    </div>

    <!-- 歌词显示 -->
    <div class="lyrics-panel" v-if="showLyrics">
      <div class="lyrics-content">
        <p v-if="!parsedLyrics?.length" class="no-lyrics">暂无歌词</p>
        <template v-else>
          <!-- 添加顶部空白占位 -->
          <p class="placeholder" style="height: 120px"></p>
          <p 
            v-for="(line, index) in parsedLyrics"
            :key="index"
            :class="{
              'active': currentLyricIndex === index,
              'near-active': isNearActive(index)
            }"
          >
            {{ line.text }}
          </p>
          <!-- 添加底部空白占位 -->
          <p class="placeholder" style="height: 120px"></p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, onMounted, watch, onUnmounted } from 'vue'
import { 
  Headset, CaretRight, Back, Right, 
  Refresh, Sort, Share, VideoPause,
  Document, RepeatPlay, DCaret
} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

export default {
  name: 'GlobalMusicPlayer',
  components: {
    Headset,
    CaretRight,
    Back,
    Right,
    Refresh,
    Sort,
    Share,
    VideoPause,
    Document,
    RepeatPlay,
    DCaret
  },
  setup() {
    // 从全局状态注入音乐播放器状态
    const musicStore = inject('musicStore')
    if (!musicStore) {
      console.error('musicStore not provided')
      return {}
    }

    // 格式化时间
    const formatTime = (seconds) => {
      const mins = Math.floor(seconds / 60)
      const secs = Math.floor(seconds % 60)
      return `${mins}:${secs.toString().padStart(2, '0')}`
    }

    // 切换播放/暂停
    const togglePlay = async () => {
      if (!musicStore.audioElement.value || !musicStore.currentMusic.value) return
      
      try {
        if (musicStore.isPlaying.value) {
          musicStore.audioElement.value.pause()
          musicStore.isPlaying.value = false
        } else {
          const playPromise = musicStore.audioElement.value.play()
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.log('Play was prevented:', error)
              musicStore.isPlaying.value = false
              ElMessage.info('请点击播放按钮开始播放')
            })
          }
          musicStore.isPlaying.value = true
        }
      } catch (error) {
        console.error('Error toggling play:', error)
        ElMessage.error('播放控制失败')
      }
    }

    // 上一首
    const previousTrack = () => {
      if (!musicStore.musicList.value?.length) return
      playPrev()
    }

    // 下一首
    const nextTrack = () => {
      if (!musicStore.musicList.value?.length) return
      playNext()
    }

    const showLyrics = ref(false)

    // 播放模式图标映射
    const playModeIcon = computed(() => {
      switch (musicStore.playMode.value) {
        case 'single':
          return Refresh
        case 'random':
          return Share
        case 'sequence':
        default:
          return Sort
      }
    })

    // 播放模式提示文字
    const playModeTitle = computed(() => {
      switch (musicStore.playMode.value) {
        case 'single':
          return '单曲循环'
        case 'random':
          return '随机播放'
        case 'sequence':
        default:
          return '顺序播放'
      }
    })

    // 切换播放模式
    const togglePlayMode = () => {
      const modes = ['sequence', 'single', 'random']
      const currentIndex = modes.indexOf(musicStore.playMode.value)
      const nextIndex = (currentIndex + 1) % modes.length
      musicStore.playMode.value = modes[nextIndex]
      // 显示当前播放模式
      ElMessage.success(playModeTitle.value)
    }

    // 切换歌词显示
    const toggleLyrics = () => {
      showLyrics.value = !showLyrics.value
    }

    // 监听音频事件
    onMounted(() => {
      if (!musicStore.audioElement.value) {
        musicStore.initAudio()
      }

      // 监听时间更新
      musicStore.audioElement.value.addEventListener('timeupdate', () => {
        if (musicStore.audioElement.value) {
          const currentTime = musicStore.audioElement.value.currentTime
          musicStore.currentTime.value = currentTime
          musicStore.updateCurrentLyric()
          scrollToActiveLyric()
        }
      })

      // 监听元数据加载
      musicStore.audioElement.value.addEventListener('loadedmetadata', () => {
        if (musicStore.audioElement.value) {
          musicStore.duration.value = musicStore.audioElement.value.duration
        }
      })

      // 监听播放结束事件
      musicStore.audioElement.value.addEventListener('ended', () => {
        playNext()
      })
    })

    onUnmounted(() => {
      if (!musicStore.audioElement.value) return
      
      // 移除播放结束事件监听
      musicStore.audioElement.value.removeEventListener('ended', () => {})
    })

    // 滚动到当前歌词
    const scrollToActiveLyric = () => {
      if (!showLyrics.value) return
      
      setTimeout(() => {
        const activeLyric = document.querySelector('.lyrics-content p.active')
        if (activeLyric) {
          activeLyric.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }, 100)
    }

    // 判断是否是接近当前播放的歌词
    const isNearActive = (index) => {
      const currentIndex = musicStore.currentLyricIndex.value
      return Math.abs(index - currentIndex) === 1
    }

    // 添加调试日志
    const currentMusic = computed(() => {
      const music = musicStore.currentMusic.value
      console.log('Current music in player:', music)
      if (music?.cover) {
        console.log('Cover URL:', music.cover)
      }
      return music
    })

    // 添加监听器来追踪音乐数据的变化
    watch(() => musicStore.currentMusic.value, (newMusic) => {
      console.log('Music changed:', newMusic)
      if (newMusic?.cover) {
        // 预加载图片
        const img = new Image()
        img.src = newMusic.cover
      }
    }, { immediate: true })

    // 播放下一首
    const playNext = async () => {
      if (!musicStore.musicList.value || musicStore.musicList.value.length === 0) return
      
      const currentIndex = musicStore.musicList.value.findIndex(
        music => music.id === musicStore.currentMusic.value?.id
      )
      
      if (currentIndex === -1) return
      
      const nextIndex = (currentIndex + 1) % musicStore.musicList.value.length
      const nextMusic = musicStore.musicList.value[nextIndex]
      
      if (nextMusic) {
        try {
          await musicStore.playMusic(nextMusic)
          const playPromise = musicStore.audioElement.value?.play()
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.log('Auto-play was prevented:', error)
              // 不显示错误提示，只在控制台记录
              musicStore.isPlaying.value = false
            })
          }
        } catch (error) {
          console.error('Error playing next track:', error)
        }
      }
    }

    // 播放上一首
    const playPrev = async () => {
      if (!musicStore.musicList.value || musicStore.musicList.value.length === 0) return
      
      const currentIndex = musicStore.musicList.value.findIndex(
        music => music.id === musicStore.currentMusic.value?.id
      )
      
      if (currentIndex === -1) return
      
      const prevIndex = currentIndex === 0 
        ? musicStore.musicList.value.length - 1 
        : currentIndex - 1
      
      const prevMusic = musicStore.musicList.value[prevIndex]
      
      if (prevMusic) {
        try {
          await musicStore.playMusic(prevMusic)
          const playPromise = musicStore.audioElement.value?.play()
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.log('Auto-play was prevented:', error)
              // 不显示错误提示，只在控制台记录
              musicStore.isPlaying.value = false
            })
          }
        } catch (error) {
          console.error('Error playing previous track:', error)
        }
      }
    }

    return {
      currentMusic,
      isPlaying: computed(() => musicStore.isPlaying.value),
      currentTime: computed({
        get: () => musicStore.currentTime.value,
        set: (value) => {
          musicStore.currentTime.value = value
          if (musicStore.audioElement.value) {
            musicStore.audioElement.value.currentTime = value
          }
        }
      }),
      duration: computed(() => musicStore.duration.value),
      parsedLyrics: computed(() => musicStore.parsedLyrics.value),
      currentLyricIndex: computed(() => musicStore.currentLyricIndex.value),
      isNearActive,
      showLyrics,
      formatTime,
      togglePlay,
      previousTrack,
      nextTrack,
      playModeIcon,
      playModeTitle,
      togglePlayMode,
      toggleLyrics,
    }
  }
}
</script>

<style lang="scss" scoped>
// Mixins
@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.music-player {
  position: fixed;
  bottom: 0;
  left: 200px;
  right: 0;
  height: 64px;
  background: #ffffff;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.08);
  border-top: 1px solid #e4e7ed;
  z-index: 9999;
  
  .player-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    
    .now-playing {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 240px;
      flex-shrink: 0;
      padding: 0 16px;
      border-right: 1px solid #e4e7ed;
      height: 100%;
      transition: background-color 0.3s ease;
      
      &:hover {
        background-color: #f5f7fa;
        cursor: pointer;
      }
      
      .playing-cover {
        width: 44px;
        height: 44px;
        border-radius: 6px;
        overflow: hidden;
        flex-shrink: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        
        &:hover {
          transform: scale(1.05);
        }
        
        .image-placeholder {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--el-fill-color-light);
          color: var(--el-text-color-secondary);
          
          .el-icon {
            font-size: 20px;
          }
        }
      }
      
      .playing-info {
        min-width: 0;
        flex: 1;
        
        h4 {
          margin: 0 0 4px;
          font-size: 14px;
          font-weight: 600;
          color: var(--el-text-color-primary);
          @include text-ellipsis;
          transition: color 0.3s ease;
          
          &:hover {
            color: var(--el-color-primary);
          }
        }
        
        p {
          margin: 0;
          font-size: 12px;
          color: var(--el-text-color-secondary);
          @include text-ellipsis;
          opacity: 0.8;
          transition: opacity 0.3s ease;
          
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    
    .player-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      flex-shrink: 0;
      width: 180px;
      height: 100%;
      border-right: 1px solid #e4e7ed;
      
      .el-button-group {
        .el-button {
          padding: 8px;
          border: none;
          background: transparent;
          transition: all 0.3s ease;
          
          &:hover {
            background-color: #f5f7fa;
            transform: scale(1.1);
            color: var(--el-color-primary);
          }
          
          &:active {
            transform: scale(0.95);
          }
          
          .el-icon {
            font-size: 18px;
          }
          
          &.control-active {
            color: var(--el-color-primary);
            background-color: var(--el-color-primary-light-9);
            
            .el-icon {
              transform: scale(1.1);
              color: var(--el-color-primary);
            }
          }
        }
      }
    }
    
    .progress-bar {
      flex: 1;
      min-width: 200px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 20px;
      height: 100%;
      
      .el-slider {
        flex: 1;
        margin: 0 8px;
        
        :deep(.el-slider__runway) {
          margin: 0;
          height: 4px;
          
          .el-slider__bar {
            background-color: var(--el-color-primary);
            transition: all 0.3s ease;
          }
          
          .el-slider__button-wrapper {
            transition: transform 0.3s ease;
            
            .el-slider__button {
              border: 2px solid var(--el-color-primary);
              width: 12px;
              height: 12px;
              transition: all 0.3s ease;
            }
          }
          
          &:hover {
            .el-slider__bar {
              background-color: var(--el-color-primary-light-3);
            }
            
            .el-slider__button-wrapper {
              transform: scale(1.2);
              
              .el-slider__button {
                border-color: var(--el-color-primary-light-3);
              }
            }
          }
        }
      }
      
      .time {
        width: 45px;
        font-size: 13px;
        color: var(--el-text-color-secondary);
        font-family: 'Roboto Mono', monospace;
        text-align: center;
        flex-shrink: 0;
        transition: color 0.3s ease;
        
        &:hover {
          color: var(--el-color-primary);
        }
      }
    }
    
    .extra-controls {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      width: 100px;
      height: 100%;
      padding: 0 16px;
      border-left: 1px solid #e4e7ed;
      
      .control-btn {
        padding: 8px;
        border: none;
        background: transparent;
        transition: all 0.3s ease;
        
        &:hover {
          background-color: #f5f7fa;
          transform: scale(1.1);
          color: var(--el-color-primary);
        }
        
        &:active {
          transform: scale(0.95);
        }
        
        .el-icon {
          font-size: 16px;
        }
        
        &.active {
          color: var(--el-color-primary);
          background-color: var(--el-color-primary-light-9);
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .music-player {
    .player-content {
      .now-playing {
        width: 220px;
      }
      
      .player-controls {
        width: 160px;
      }
      
      .extra-controls {
        width: 90px;
      }
    }
  }
}

@media (max-width: 992px) {
  .music-player {
    .player-content {
      .now-playing {
        width: 200px;
        padding: 0 12px;
      }
      
      .player-controls {
        width: 140px;
      }
      
      .progress-bar {
        padding: 0 12px;
      }
      
      .extra-controls {
        width: 80px;
        padding: 0 12px;
      }
    }
  }
}

@media (max-width: 768px) {
  .music-player {
    left: 0;
    height: 64px;
    
    .player-content {
      flex-wrap: nowrap;
      justify-content: space-between;
      
      .now-playing {
        width: 40%;
        padding: 0 8px;
        border-right: none;
        
        .playing-cover {
          width: 36px;
          height: 36px;
        }
        
        .playing-info {
          h4 {
            font-size: 13px;
          }
          
          p {
            font-size: 11px;
          }
        }
      }
      
      .player-controls {
        width: auto;
        border-right: none;
        gap: 4px;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        flex: 1;
        
        .el-button-group {
          display: flex;
          gap: 4px;
          
          .el-button {
            padding: 6px;
            
            .el-icon {
              font-size: 16px;
            }
          }
        }
      }
      
      .progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        min-width: 0;
        padding: 0;
        background: var(--el-border-color-light);
        pointer-events: none;
        
        .time {
          display: none;
        }
        
        .el-slider {
          margin: 0;
          height: 2px;
          
          :deep(.el-slider__runway) {
            height: 2px;
            
            .el-slider__button-wrapper {
              display: none;
            }
          }
        }
      }
      
      .extra-controls {
        width: auto;
        padding: 0 8px;
        border-left: none;
        display: flex;
        gap: 4px;
        
        .control-btn {
          padding: 6px;
          
          .el-icon {
            font-size: 16px;
          }
        }
      }
    }
  }
  
  .lyrics-panel {
    bottom: 70px;
    right: 8px;
    width: calc(100% - 16px);
    max-width: 320px;
    height: 360px;
  }
}

@media (max-width: 480px) {
  .music-player {
    .player-content {
      .now-playing {
        width: 35%;
        
        .playing-info {
          h4 {
            font-size: 12px;
          }
          
          p {
            font-size: 10px;
          }
        }
      }
      
      .player-controls {
        .el-button-group {
          .el-button {
            padding: 4px;
            
            .el-icon {
              font-size: 14px;
            }
          }
        }
      }
      
      .extra-controls {
        .control-btn {
          padding: 4px;
          
          .el-icon {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.lyrics-panel {
  position: fixed;
  bottom: 70px;
  right: 16px;
  width: 320px;
  height: 400px;
  background: #ffffff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 999;
  transition: all 0.3s ease;
  border: 1px solid #e4e7ed;
  
  // 添加小三角形
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    right: 45px;
    width: 16px;
    height: 16px;
    background: #ffffff;
    transform: rotate(45deg);
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }
  
  .lyrics-content {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      black 10%,
      black 90%,
      transparent 100%
    );

    &::-webkit-scrollbar {
      width: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .no-lyrics {
      color: #909399;
      font-style: italic;
      margin-top: 160px;
      font-size: 14px;
    }

    p {
      width: 100%;
      max-width: 280px;
      margin: 12px 0;
      padding: 4px 0;
      color: #909399;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      font-size: 13px;
      line-height: 1.6;
      text-align: center;
      opacity: 0.6;
      transform: scale(0.95);
      
      &:hover {
        opacity: 0.8;
        transform: scale(0.98);
      }

      &.active {
        color: var(--el-color-primary);
        font-size: 15px;
        font-weight: 500;
        opacity: 1;
        transform: scale(1);
        padding: 6px 0;
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 20px;
          height: 2px;
          background-color: var(--el-color-primary);
          border-radius: 1px;
          opacity: 0.6;
        }
      }
      
      &.near-active {
        color: #606266;
        opacity: 0.8;
        transform: scale(0.98);
      }
    }
  }
}
</style> 