// 图标缓存服务
const CACHE_PREFIX = 'favicon_cache_';
const CACHE_INDEX = 'favicon_cache_index';

// 将图片转换为base64
const convertImageToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';  // 尝试启用跨域
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const base64 = canvas.toDataURL('image/png');
      resolve(base64);
    };
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = url;
  });
};

// 保存图标到本地存储
export const saveIconToCache = async (domain, iconUrl) => {
  try {
    // 如果已经是base64格式，直接保存
    if (iconUrl.startsWith('data:')) {
      localStorage.setItem(CACHE_PREFIX + domain, iconUrl);
      updateCacheIndex(domain);
      return iconUrl;
    }

    // 转换图片为base64
    const base64data = await convertImageToBase64(iconUrl);
    
    // 保存到localStorage
    localStorage.setItem(CACHE_PREFIX + domain, base64data);
    updateCacheIndex(domain);
    
    return base64data;
  } catch (error) {
    console.error('Error caching icon:', error);
    return null;
  }
};

// 更新缓存索引
const updateCacheIndex = (domain) => {
  const cacheIndex = JSON.parse(localStorage.getItem(CACHE_INDEX) || '[]');
  if (!cacheIndex.includes(domain)) {
    cacheIndex.push(domain);
    localStorage.setItem(CACHE_INDEX, JSON.stringify(cacheIndex));
  }
};

// 从缓存获取图标
export const getIconFromCache = (domain) => {
  return localStorage.getItem(CACHE_PREFIX + domain);
};

// 检查图标是否已缓存
export const isIconCached = (domain) => {
  return !!localStorage.getItem(CACHE_PREFIX + domain);
};

// 获取所有已缓存的域名
export const getCachedDomains = () => {
  return JSON.parse(localStorage.getItem(CACHE_INDEX) || '[]');
}; 