<template>
  <div class="comment-form">
    <h3>发表评论</h3>
    <el-form 
      ref="formRef"
      :model="form"
      :rules="rules"
      label-width="80px"
    >
      <div class="form-row">
        <el-form-item label="昵称" prop="nickname" class="nickname-item">
          <el-input v-model="form.nickname" placeholder="请输入昵称" />
        </el-form-item>
        
        <el-form-item label="邮箱" prop="email" class="email-item">
          <el-input v-model="form.email" placeholder="请输入邮箱" />
        </el-form-item>
      </div>
      
      <el-form-item label="评论" prop="content">
        <el-input
          v-model="form.content"
          type="textarea"
          :rows="4"
          placeholder="请输入评论内容"
        />
      </el-form-item>
      
      <el-form-item>
        <el-button type="primary" @click="submitForm">
          发表评论
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import apiService from '@/api/service'

export default {
  name: 'CommentForm',
  props: {
    articleId: {
      type: [Number, String],
      required: true
    },
    parentId: {
      type: [Number, String],
      default: null
    }
  },
  emits: ['comment-added'],
  setup(props, { emit }) {
    const formRef = ref(null)
    const form = reactive({
      nickname: '',
      email: '',
      content: ''
    })
    
    const rules = {
      nickname: [
        { required: true, message: '请输入昵称', trigger: 'blur' },
        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
      ],
      email: [
        { required: true, message: '请输入邮箱', trigger: 'blur' },
        { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
      ],
      content: [
        { required: true, message: '请输入评论内容', trigger: 'blur' },
        { min: 5, message: '评论内容至少 5 个字符', trigger: 'blur' }
      ]
    }
    
    const submitForm = async () => {
      if (!formRef.value) return
      
      try {
        await formRef.value.validate()
        
        const data = {
          article: props.articleId,
          content: form.content.trim(),
          nickname: form.nickname.trim(),
          email: form.email.trim(),
          parent: props.parentId || null
        }

        console.log('Submitting comment:', data)
        const response = await apiService.createComment(data)
        console.log('Comment submit response:', response)
        
        ElMessage.success('评论发布成功')
        emit('comment-added')
        resetForm()
      } catch (error) {
        console.error('Error submitting comment:', error)
        if (error.response) {
          console.error('Error response:', error.response)
          const message = error.response.data?.message || '评论发布失败'
          ElMessage.error(message)
        } else {
          ElMessage.error('评论发布失败，请稍后重试')
        }
      }
    }
    
    const resetForm = () => {
      formRef.value.resetFields()
    }
    
    return {
      formRef,
      form,
      rules,
      submitForm,
      resetForm
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-form {
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  
  h3 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #303133;
  }

  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 18px;
    
    .nickname-item {
      flex: 1;
      margin-bottom: 0;
    }
    
    .email-item {
      flex: 2;
      margin-bottom: 0;
    }
  }
}
</style> 