<template>
  <page-container>
    <div class="article-detail">
      <!-- 加载状态 -->
      <div v-if="loading" class="loading-wrapper">
        <el-skeleton :rows="10" animated />
      </div>
      
      <!-- 错误提示 -->
      <el-alert
        v-if="error"
        :title="error"
        type="error"
        show-icon
        :closable="false"
        style="margin-bottom: 20px;"
      />

      <!-- 密码验证表单 -->
      <div v-if="showPasswordForm" class="password-form">
        <h3>需要密码访问</h3>
        <p>这篇文章需要密码才能访问</p>
        <el-input
          v-model="password"
          type="password"
          placeholder="请输入访问密码"
          show-password
          @keyup.enter="verifyPassword"
        />
        <el-button 
          type="primary"
          style="margin-top: 20px;"
          @click="verifyPassword"
        >
          验证密码
        </el-button>
      </div>

      <!-- 文章内容 -->
      <template v-if="article">
        <!-- 添加文章大纲组件 -->
        <article-outline :content-ref="contentRef" />
        
        <div class="article-header">
          <h1>{{ article.title }}</h1>
          
          <!-- 修改分享按钮组 -->
          <div class="share-buttons">
            <el-button-group>
              <el-button 
                type="primary" 
                :icon="Share"
                @click="showWeChatShareDialog = true"
              >
                分享到微信
              </el-button>
              <el-button 
                type="success"
                :icon="QrCode"
                @click="showWeChatShareDialog = true"
              >
                分享到朋友圈
              </el-button>
              <el-button 
                type="warning"
                :icon="Share"
                @click="shareToWeibo"
              >
                分享到微博
              </el-button>
            </el-button-group>
          </div>

          <div class="article-meta">
            <span>
              <el-icon><Calendar /></el-icon>
              {{ formatDate(article.created_time) }}
            </span>
            <span>
              <el-icon><View /></el-icon>
              {{ article.views }} 阅读
            </span>
            <span>
              <el-icon><Folder /></el-icon>
              <template v-if="article.categories && article.categories.length > 0">
                <router-link 
                  v-for="(category, index) in article.categories" 
                  :key="category.id" 
                  :to="`/category/${category.id}`"
                >
                  {{ category.name }}{{ index < article.categories.length - 1 ? ', ' : '' }}
                </router-link>
              </template>
              <span v-else>未分类</span>
            </span>
          </div>
          <div class="article-tags" v-if="article.tags && article.tags.length">
            <el-tag
              v-for="tag in article.tags"
              :key="tag.id"
              size="small"
              effect="plain"
              class="tag-item"
            >
              <router-link :to="`/tag/${tag.id}`">{{ tag.name }}</router-link>
            </el-tag>
          </div>
        </div>

        <div class="article-content markdown-body" v-html="renderedContent" ref="contentRef"></div>
        
        <!-- 添加打赏区域 -->
        <div class="reward-section">
          <h3 class="reward-title">支持创作</h3>
          <p class="reward-description">如果您觉得这篇文章对您有帮助，欢迎打赏支持！</p>
          <article-reward />
        </div>
        
        <div class="article-footer">
          <div class="article-nav">
            <div v-if="prevArticle" class="prev-article">
              <router-link :to="`/article/${prevArticle.id}`">
                <el-icon><ArrowLeft /></el-icon>
                {{ prevArticle.title }}
              </router-link>
            </div>
            <div v-if="nextArticle" class="next-article">
              <router-link :to="`/article/${nextArticle.id}`">
                {{ nextArticle.title }}
                <el-icon><ArrowRight /></el-icon>
              </router-link>
            </div>
          </div>
        </div>
      </template>

      <comment-form 
        :article-id="Number(articleId)" 
        @comment-added="handleCommentAdded" 
      />
      <comment-list 
        ref="commentListRef"
        :article-id="Number(articleId)" 
      />

      <!-- 添加微信分享对话框 -->
      <el-dialog
        v-model="showWeChatShareDialog"
        title="分享到微信"
        width="300px"
        align-center
      >
        <div class="wechat-share-dialog">
          <div class="qrcode-container">
            <img :src="shareQrcodeUrl" alt="二维码" class="qrcode-img" />
          </div>
          <p class="share-tip">请使用微信扫描二维码</p>
          <p class="share-tip">可以分享到微信好友或朋友圈</p>
        </div>
      </el-dialog>
    </div>
  </page-container>
</template>

<script>
export default {
  name: 'ArticleDetailPage',
  components: {
    CommentForm,
    CommentList,
    ArticleReward: Reward,
    PageContainer,
    ArticleOutline
  }
}
</script>

<script setup>
import { ref, onMounted, computed, nextTick, watch, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { marked } from 'marked'
import 'github-markdown-css/github-markdown.css'
import CommentForm from '@/components/CommentForm.vue'
import CommentList from '@/components/CommentList.vue'
import PageContainer from '@/components/PageContainer.vue'
import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-scss'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-markdown'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-yaml'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-php'
import Clipboard from 'clipboard'
import { ElMessage } from 'element-plus'
import apiService from '@/api/service'
import { Share, QrCode } from '@element-plus/icons-vue'
import Reward from '@/components/Reward.vue'
import ArticleOutline from '@/components/ArticleOutline.vue'

// 配置 marked
marked.setOptions({
  highlight: function(code, lang) {
    if (lang && Prism.languages[lang]) {
      try {
        return Prism.highlight(code, Prism.languages[lang], lang)
      } catch (e) {
        console.error('Prism highlighting error:', e)
        return code
      }
    }
    return code
  },
  langPrefix: 'language-'
})

const route = useRoute()
const articleId = ref(route.params.id)
const article = ref(null)
const loading = ref(true)
const error = ref(null)
const commentListRef = ref(null)
const prevArticle = ref(null)
const nextArticle = ref(null)
const showPasswordForm = ref(false)
const password = ref('')
const contentRef = ref(null)

// 获取当前页面URL
const currentUrl = computed(() => {
  return window.location.href
})

const renderedContent = computed(() => {
  if (!article.value?.content) return ''
  return marked.parse(article.value.content)
})

const fetchArticle = async () => {
  try {
    loading.value = true;
    const response = await apiService.getArticleById(articleId.value);
    article.value = response.data;
    loading.value = false;
  } catch (error) {
    console.log('\n Error fetching article: \n', error);
    if (error.response?.status === 403 && error.response?.data?.is_protected) {
      // 检查验证状态
      if (!checkVerificationStatus()) {
        showPasswordForm.value = true;
      } else {
        // 如果验证状态有效，重试获取文章
        await fetchArticle();
      }
    } else {
      ElMessage.error('获取文章失败');
    }
    loading.value = false;
  }
};

const verifyPassword = async () => {
  if (!password.value) {
    ElMessage.warning('请输入密码');
    return;
  }

  try {
    const response = await apiService.verifyArticlePassword(articleId.value, password.value);
    
    if (response.data && response.data.status === 'success') {
      showPasswordForm.value = false;
      password.value = '';
      
      // 将验证状态保存到 sessionStorage，并设置过期时间（30分钟）
      const verificationData = {
        timestamp: Date.now(),
        articleId: articleId.value
      };
      sessionStorage.setItem(`article_verified_${articleId.value}`, JSON.stringify(verificationData));
      
      await fetchArticle();
    } else {
      ElMessage.error(response.data?.message || '密码验证失败');
    }
  } catch (error) {
    console.error('Error verifying password:', error);
    ElMessage.error(error.response?.data?.message || '密码验证失败');
  }
};

// 检查密码验证状态是否有效
const checkVerificationStatus = () => {
  const verificationData = sessionStorage.getItem(`article_verified_${articleId.value}`);
  if (!verificationData) return false;

  try {
    const { timestamp, articleId: storedArticleId } = JSON.parse(verificationData);
    const expirationTime = 30 * 60 * 1000; // 30分钟过期
    
    // 检查是否过期且是否是同一篇文章
    if (Date.now() - timestamp <= expirationTime && storedArticleId === articleId.value) {
      return true;
    } else {
      // 如果过期或不是同一篇文章，删除验证状态
      sessionStorage.removeItem(`article_verified_${articleId.value}`);
      return false;
    }
  } catch {
    return false;
  }
};

const formatDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}

const handleCommentAdded = () => {
  // 刷新评论列表
  commentListRef.value?.fetchComments()
}

const initCodeBlocks = () => {
  nextTick(() => {
    // 为每个代码块添加功能按钮
    const codeBlocks = document.querySelectorAll('pre code')
    codeBlocks.forEach((codeBlock, index) => {
      const pre = codeBlock.parentNode
      
      // 创建按钮容器
      const buttonContainer = document.createElement('div')
      buttonContainer.className = 'code-button-container'
      
      // 创建复制按钮
      const copyButton = document.createElement('button')
      copyButton.className = 'code-button copy-button'
      copyButton.innerHTML = '复制'
      copyButton.setAttribute('data-clipboard-target', `#code-block-${index}`)
      
      // 创建折叠按钮
      const foldButton = document.createElement('button')
      foldButton.className = 'code-button fold-button'
      foldButton.innerHTML = '折叠'
      foldButton.onclick = () => {
        const isCollapsed = pre.classList.toggle('collapsed')
        foldButton.innerHTML = isCollapsed ? '展开' : '折叠'
      }
      
      // 获取代码语言
      const language = codeBlock.className.match(/language-([^\s]+)/)
      if (language) {
        const langTag = document.createElement('span')
        langTag.className = 'code-language'
        langTag.innerHTML = language[1]
        // 创建语言标识容器
        const langContainer = document.createElement('div')
        langContainer.className = 'code-language-container'
        langContainer.appendChild(langTag)
        pre.insertBefore(langContainer, codeBlock)
      }
      
      // 为代码块添加id
      codeBlock.id = `code-block-${index}`
      
      // 将按钮添加到容器
      buttonContainer.appendChild(foldButton)
      buttonContainer.appendChild(copyButton)
      
      // 将容器插入到代码块之前
      pre.insertBefore(buttonContainer, codeBlock)
    })
    
    // 初始化复制功能
    const clipboard = new Clipboard('.copy-button')
    clipboard.on('success', (e) => {
      const button = e.trigger
      button.innerHTML = '已复制!'
      setTimeout(() => {
        button.innerHTML = '复制'
      }, 2000)
    })
  })
}

// 在文章内容更新后初始化代码块
watch(() => article.value, () => {
  if (article.value) {
    initCodeBlocks()
  }
}, { immediate: true })

onMounted(() => {
  fetchArticle()
})

// 微信分享相关
const showWeChatShareDialog = ref(false)
const shareQrcodeUrl = computed(() => {
  if (!currentUrl.value) return ''
  return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(currentUrl.value)}`
})

// 添加分享相关的方法
const shareToWeibo = () => {
  const shareUrl = `https://service.weibo.com/share/share.php?url=${encodeURIComponent(currentUrl.value)}&title=${encodeURIComponent(article.value.title)}&pic=&appkey=&ralateUid=&language=zh_cn`
  window.open(shareUrl, '_blank', 'width=615,height=505')
}

// 在组件卸载时清除验证状态
onUnmounted(() => {
  sessionStorage.removeItem(`article_verified_${articleId.value}`);
});
</script>

<style lang="scss" scoped>
.password-form {
  max-width: 500px;
  margin: 40px auto;
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

  h3 {
    color: #303133;
    margin-bottom: 20px;
  }
}

.article-detail {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  
  .article-header {
    margin-bottom: 30px;
    text-align: center;
    
    h1 {
      font-size: 28px;
      color: var(--el-color-primary);
      margin: 0 0 20px;
      font-weight: 600;
    }
    
    .share-buttons {
      margin: 16px 0;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    .article-meta {
      color: #909399;
      font-size: 14px;
      margin-bottom: 15px;
      
      span {
        margin-right: 20px;
        display: inline-flex;
        align-items: center;
        
        .el-icon {
          margin-right: 4px;
        }
        
        a {
          color: inherit;
          text-decoration: none;
          
          &:hover {
            color: var(--el-color-primary);
          }
        }
      }
    }
    
    .article-tags {
      .tag-item {
        margin-right: 10px;
        
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }

  .article-content {
    margin-bottom: 40px;
    line-height: 1.8;
    
    &.markdown-body {
      background-color: transparent;
      font-family: inherit;
      
      pre {
        background-color: #f6f8fa;
        border-radius: 6px;
      }
      
      img {
        max-width: 100%;
        display: block;
        margin: 20px auto;
      }
      
      h1, h2, h3, h4, h5, h6 {
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: 600;
        line-height: 1.25;
      }
      
      a {
        color: var(--el-color-primary);
      }
      
      table {
        display: block;
        width: 100%;
        overflow: auto;
        margin-top: 0;
        margin-bottom: 16px;
      }
      
      code {
        padding: 0.2em 0.4em;
        margin: 0;
        font-size: 85%;
        background-color: rgba(175, 184, 193, 0.2);
        border-radius: 6px;
      }
      
      pre code {
        padding: 16px;
        background-color: transparent;
      }

      .code-block-wrapper {
        position: relative;
        margin: 1em 0;
        background-color: #f6f8fa;
        border-radius: 6px;
        
        &:hover .copy-button {
          opacity: 1;
        }

        .line-numbers {
          position: absolute;
          left: 0;
          top: 0;
          padding: 16px 8px;
          color: #999;
          background-color: #f6f8fa;
          border-right: 1px solid #ddd;
          border-radius: 6px 0 0 6px;
          text-align: right;
          user-select: none;
          font-family: monospace;
        }

        code {
          margin-left: 40px;  // 为行号留出空间
          padding: 16px !important;
          background-color: transparent !important;
        }

        .copy-button {
          position: absolute;
          top: 8px;
          right: 8px;
          padding: 4px 8px;
          font-size: 12px;
          color: #666;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 4px;
          cursor: pointer;
          opacity: 0;
          transition: all 0.3s;

          &:hover {
            color: var(--el-color-primary);
            border-color: var(--el-color-primary);
          }
        }
      }

      pre {
        margin: 0;
        padding: 0;
        background-color: transparent;
      }
    }
  }

  .article-footer {
    border-top: 1px solid #ebeef5;
    padding-top: 20px;
    
    .article-nav {
      display: flex;
      justify-content: space-between;
      
      .prev-article, .next-article {
        max-width: 45%;
        
        a {
          display: flex;
          align-items: center;
          color: #606266;
          text-decoration: none;
          
          &:hover {
            color: var(--el-color-primary);
          }
          
          .el-icon {
            margin: 0 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .article-detail {
    padding: 15px;
    
    .article-header {
      h1 {
        font-size: 24px;
      }
      
      .article-meta {
        flex-direction: column;
        gap: 10px;
      }

      .share-buttons {
        flex-direction: column;
        align-items: stretch;
        
        .el-button-group {
          display: flex;
          flex-direction: column;
          gap: 8px;
          
          .el-button {
            width: 100%;
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

:deep(pre) {
  position: relative;
  background-color: #1e1e1e !important;
  border-radius: 6px;
  padding: 1em;
  margin: 1em 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  
  &.collapsed {
    max-height: 50px !important;
    overflow: hidden;
    
    code {
      overflow: hidden;
    }
  }
  
  code {
    background-color: transparent;
    color: #fff;
    padding: 0;
    font-family: 'Fira Code', monospace;
    font-size: 14px;
    line-height: 1.5;
    display: block;
    overflow: auto;
    max-height: 600px;
    margin-top: 1.5em; // 为语言标识留出空间
    
    // 代码高亮基础样式
    .token {
      &.comment { color: #6a9955; }
      &.string { color: #ce9178; }
      &.number { color: #b5cea8; }
      &.keyword { color: #569cd6; }
      &.function { color: #dcdcaa; }
      &.operator { color: #d4d4d4; }
      &.punctuation { color: #d4d4d4; }
      &.class-name { color: #4ec9b0; }
      &.property { color: #9cdcfe; }
      &.boolean { color: #569cd6; }
      &.constant { color: #4fc1ff; }
      &.variable { color: #9cdcfe; }
      &.parameter { color: #9cdcfe; }
      &.regex { color: #d16969; }
      &.important { color: #569cd6; }
      &.builtin { color: #4ec9b0; }
    }
  }
  
  .code-language-container {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    z-index: 2;
    
    .code-language {
      color: #fff;
      font-size: 12px;
      padding: 2px 6px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      text-transform: uppercase;
    }
  }
  
  .code-button-container {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 2;
    display: flex;
    gap: 8px;
    align-items: center;
    
    .code-button {
      background-color: rgba(255, 255, 255, 0.1);
      border: none;
      border-radius: 4px;
      color: #fff;
      padding: 4px 8px;
      font-size: 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

:deep(.token) {
  &.comment { color: #6a9955; }
  &.string { color: #ce9178; }
  &.number { color: #b5cea8; }
  &.keyword { color: #569cd6; }
  &.function { color: #dcdcaa; }
  &.operator { color: #d4d4d4; }
  &.punctuation { color: #d4d4d4; }
}

.wechat-share-dialog {
  text-align: center;
  padding: 20px;

  .qrcode-container {
    margin-bottom: 20px;
    
    .qrcode-img {
      max-width: 200px;
      height: auto;
    }
  }

  .share-tip {
    margin: 10px 0;
    color: #606266;
    font-size: 14px;
  }
}

/* 添加打赏区域样式 */
.reward-section {
  margin: 40px -20px;  /* 使用负边距延伸到容器边缘 */
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 0;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.reward-title {
  font-size: 20px;
  color: #303133;
  margin-bottom: 12px;
}

.reward-description {
  color: #606266;
  margin-bottom: 20px;
  font-size: 14px;
}
</style> 