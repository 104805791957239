import { ref } from 'vue'
import { ElMessage } from 'element-plus'

export const createMusicStore = () => {
  const currentMusic = ref(null)
  const isPlaying = ref(false)
  const currentTime = ref(0)
  const duration = ref(0)
  const audioElement = ref(null)
  const musicList = ref([])
  const playMode = ref('sequence')  // 默认顺序播放
  const parsedLyrics = ref([])
  const currentLyricIndex = ref(-1)

  const parseLyrics = (lyricsText) => {
    if (!lyricsText) return []
    
    try {
      // 按行分割歌词
      const lines = lyricsText.split('\n')
      const timeRegex = /\[(\d{2}):(\d{2})\.(\d{2,3})\]/
      
      const lyrics = lines
        .map(line => {
          const match = timeRegex.exec(line)
          if (!match) {
            return null
          }
          
          const minutes = parseInt(match[1])
          const seconds = parseInt(match[2])
          const milliseconds = parseInt(match[3])
          const time = minutes * 60 + seconds + milliseconds / 1000
          const text = line.replace(timeRegex, '').trim()
          
          return text ? { time, text } : null
        })
        .filter(line => line !== null) // 过滤掉空行和解析失败的行
        .sort((a, b) => a.time - b.time)

      console.log('Parsed lyrics:', lyrics)
      return lyrics
    } catch (error) {
      console.error('Error parsing lyrics:', error)
      return []
    }
  }

  const initAudio = () => {
    if (!audioElement.value) {
      audioElement.value = new Audio()
      
      // 添加音频结束事件监听器
      audioElement.value.addEventListener('ended', handlePlayEnd)
    }
    return audioElement.value
  }

  const playMusic = async (music) => {
    try {
      if (!music) return
      
      const audio = initAudio()
      if (!audio) {
        throw new Error('Failed to initialize audio')
      }
      
      // 如果是同一首歌，只切换播放状态
      if (currentMusic.value?.id === music.id) {
        return togglePlay()
      }

      console.log('Original music data:', {
        ...music,
        cover: music.cover,
        audio_file: music.audio_file
      })

      // 处理音乐数据
      const processedMusic = {
        ...music,
        audio_file: processUrl(music.audio_file),
        cover: processUrl(music.cover)
      }

      console.log('Processed music data:', {
        ...processedMusic,
        cover: processedMusic.cover,
        audio_file: processedMusic.audio_file
      })

      // 设置新的音乐
      currentMusic.value = processedMusic
      audio.src = processedMusic.audio_file
      
      // 解析歌词
      console.log('Setting lyrics:', processedMusic.lyrics)
      parsedLyrics.value = parseLyrics(processedMusic.lyrics || '')
      currentLyricIndex.value = -1

      // 重置音频状态
      audio.currentTime = 0
      currentTime.value = 0
      
      // 添加timeupdate事件监听器
      audio.removeEventListener('timeupdate', updateCurrentLyric)
      audio.addEventListener('timeupdate', () => {
        currentTime.value = audio.currentTime
        updateCurrentLyric()
      })

      // 播放音乐
      try {
        const playPromise = audio.play()
        if (playPromise !== undefined) {
          playPromise.then(() => {
            isPlaying.value = true
          }).catch(error => {
            console.log('Play was prevented:', error)
            isPlaying.value = false
            ElMessage.warning('请点击播放按钮开始播放')
          })
        }
      } catch (error) {
        console.log('Play was prevented:', error)
        isPlaying.value = false
        ElMessage.warning('请点击播放按钮开始播放')
      }
    } catch (error) {
      console.error('Error playing music:', error)
      ElMessage.error('播放失败')
    }
  }

  const togglePlay = async () => {
    if (!audioElement.value || !currentMusic.value) return
    
    try {
      if (isPlaying.value) {
        audioElement.value.pause()
        isPlaying.value = false
      } else {
        await audioElement.value.play()
        isPlaying.value = true
      }
    } catch (error) {
      console.error('Error toggling play:', error)
      ElMessage.error('播放控制失败')
    }
  }

  const handlePlayEnd = () => {
    if (!musicList.value.length) return

    const currentIndex = musicList.value.findIndex(m => m.id === currentMusic.value?.id)
    let nextMusic = null
    let availableSongs = []
    let randomIndex = 0

    switch (playMode.value) {
      case 'single':
        // 单曲循环：重新播放当前歌曲
        nextMusic = currentMusic.value
        break
      case 'random':
        // 随机播放：随机选择一首歌曲（排除当前歌曲）
        availableSongs = musicList.value.filter(m => m.id !== currentMusic.value?.id)
        if (availableSongs.length === 0) {
          availableSongs = musicList.value // 如果只有一首歌，就用完整列表
        }
        randomIndex = Math.floor(Math.random() * availableSongs.length)
        nextMusic = availableSongs[randomIndex]
        break
      case 'sequence':
      default:
        // 顺序播放：播放下一首歌曲，如果是最后一首则停止
        if (currentIndex < musicList.value.length - 1) {
          nextMusic = musicList.value[currentIndex + 1]
        }
        break
    }

    if (nextMusic) {
      playMusic(nextMusic)
    } else {
      // 如果没有下一首歌曲，停止播放
      isPlaying.value = false
      if (audioElement.value) {
        audioElement.value.currentTime = 0
      }
    }
  }

  const updateCurrentLyric = () => {
    if (!audioElement.value || !parsedLyrics.value?.length) return
    
    const currentTimeSeconds = audioElement.value.currentTime
    
    // 找到当前时间对应的歌词索引
    let index = -1
    for (let i = 0; i < parsedLyrics.value.length; i++) {
      if (currentTimeSeconds >= parsedLyrics.value[i].time) {
        if (i === parsedLyrics.value.length - 1 || currentTimeSeconds < parsedLyrics.value[i + 1].time) {
          index = i
          break
        }
      }
    }
    
    // 更新当前歌词索引
    if (index !== currentLyricIndex.value) {
      currentLyricIndex.value = index
      console.log('Current lyric index:', index)
    }
  }

  // 添加 URL 处理函数
  const processUrl = (url) => {
    if (!url) return ''
    if (url.startsWith('http')) return url
    if (url.startsWith('/')) {
      return `http://localhost:8000${url}`
    }
    return `http://localhost:8000/${url}`
  }

  // 修改 setMusicList 函数
  const setMusicList = (list) => {
    if (!Array.isArray(list)) {
      console.error('setMusicList: list must be an array')
      return
    }
    
    console.log('Original music list:', list)
    
    // 处理音乐列表中的每一首歌
    const processedList = list.map(music => ({
      ...music,
      audio_file: processUrl(music.audio_file),
      cover: processUrl(music.cover)
    }))
    
    console.log('Processed music list:', processedList)
    musicList.value = processedList
  }

  return {
    currentMusic,
    isPlaying,
    currentTime,
    duration,
    audioElement,
    musicList,
    playMode,
    parsedLyrics,
    currentLyricIndex,
    initAudio,
    playMusic,
    togglePlay,
    updateCurrentLyric,
    setMusicList  // 导出 setMusicList 方法
  }
} 