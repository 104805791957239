<template>
  <page-container title="搜索结果" :subtitle="`关键词：${searchQuery}`">
    <div class="search-result">
      <!-- 加载状态 -->
      <div v-if="loading" class="loading-state">
        <el-skeleton :rows="3" animated />
      </div>

      <!-- 搜索结果列表 -->
      <div v-else-if="articles.length > 0" class="article-list">
        <el-card v-for="article in articles" :key="article.id" class="article-card">
          <div class="article-content">
            <h2 class="title" @click="goToArticle(article.id)">{{ article.title }}</h2>
            <p class="description">{{ article.excerpt }}</p>
            <div class="meta">
              <span class="time">
                <el-icon><Calendar /></el-icon>
                {{ formatDate(article.created_time) }}
              </span>
              <span class="views">
                <el-icon><View /></el-icon>
                {{ article.views }} 阅读
              </span>
              <span class="category" v-if="article.category" @click="goToCategory(article.category.id)">
                <el-icon><Folder /></el-icon>
                {{ article.category.name }}
              </span>
            </div>
          </div>
        </el-card>
      </div>

      <!-- 无结果状态 -->
      <el-empty 
        v-else 
        description="未找到相关文章" 
        :image-size="200"
      >
        <template #image>
          <el-icon class="empty-icon"><Search /></el-icon>
        </template>
      </el-empty>
    </div>
  </page-container>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Calendar, View, Folder, Search } from '@element-plus/icons-vue'
import dayjs from 'dayjs'
import apiService from '@/api/service'
import { ElMessage } from 'element-plus'
import PageContainer from '@/components/PageContainer.vue'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const articles = ref([])
const searchQuery = ref('')

// 格式化日期
const formatDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

// 跳转到文章详情
const goToArticle = (id) => {
  router.push({
    name: 'ArticleDetail',
    params: { id }
  })
}

// 跳转到分类
const goToCategory = (id) => {
  router.push({
    name: 'CategoryDetail',
    params: { id }
  })
}

// 搜索文章
const searchArticles = async () => {
  if (!searchQuery.value) return
  
  loading.value = true
  try {
    const response = await apiService.searchArticles(searchQuery.value)
    articles.value = response.data.results || []
  } catch (error) {
    console.error('Error searching articles:', error)
    ElMessage.error('搜索失败')
    articles.value = []
  } finally {
    loading.value = false
  }
}

// 监听路由参数变化
watch(
  () => route.query.q,
  (newQuery) => {
    searchQuery.value = newQuery || ''
    if (searchQuery.value) {
      searchArticles()
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.search-result {
  padding: 20px;
  
  .article-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .article-card {
      transition: all 0.3s;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }
      
      .article-content {
        .title {
          margin: 0 0 10px;
          font-size: 18px;
          color: var(--el-text-color-primary);
          cursor: pointer;
          
          &:hover {
            color: var(--el-color-primary);
          }
        }
        
        .description {
          margin: 0 0 15px;
          font-size: 14px;
          color: var(--el-text-color-regular);
          line-height: 1.6;
        }
        
        .meta {
          display: flex;
          gap: 20px;
          color: var(--el-text-color-secondary);
          font-size: 13px;
          
          span {
            display: flex;
            align-items: center;
            gap: 4px;
            
            &.category {
              cursor: pointer;
              color: var(--el-color-primary);
              
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  
  .empty-icon {
    font-size: 48px;
    color: var(--el-text-color-secondary);
  }
}

@media (max-width: 768px) {
  .search-result {
    padding: 15px;
    
    .article-list {
      gap: 15px;
      
      .article-card {
        .article-content {
          .title {
            font-size: 16px;
          }
          
          .description {
            font-size: 13px;
          }
          
          .meta {
            flex-wrap: wrap;
            gap: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style> 