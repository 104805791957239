<template>
  <page-container title="文章分类" subtitle="探索不同主题的文章">
    <div class="category-page">
      <!-- 加载状态 -->
      <div v-if="loading" class="loading-wrapper">
        <el-skeleton :rows="3" animated />
      </div>
      
      <!-- 错误提示 -->
      <el-alert
        v-if="error"
        :title="error"
        type="error"
        show-icon
        :closable="false"
        style="margin-bottom: 20px;"
      />

      <!-- 分类列表 -->
      <div v-else class="category-list">
        <template v-if="categories && categories.length > 0">
          <el-card
            v-for="category in categories"
            :key="category.id"
            class="category-card"
            shadow="hover"
            :body-style="{ padding: '0px' }"
          >
            <div class="category-content" :style="getCategoryStyle()">
              <div class="category-header">
                <h3>{{ category.name }}</h3>
                <el-tag type="primary" round effect="dark">{{ category.article_count }} 篇文章</el-tag>
              </div>
              <p class="category-desc">{{ category.description || '暂无描述' }}</p>
              <div class="category-footer">
                <el-button 
                  type="primary" 
                  @click="viewCategory(category.id)"
                  class="view-button"
                >
                  浏览文章
                  <el-icon class="el-icon--right"><ArrowRight /></el-icon>
                </el-button>
              </div>
            </div>
          </el-card>
        </template>
        <el-empty v-else description="暂无分类" />
      </div>

      <!-- 添加打赏区域 -->
      <div class="reward-section">
        <h3 class="reward-title">支持创作</h3>
        <p class="reward-description">如果这些内容对您有帮助，欢迎打赏支持！</p>
        <article-reward />
      </div>
    </div>
  </page-container>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import PageContainer from '@/components/PageContainer.vue'
import ArticleReward from '@/components/Reward.vue'

export default {
  name: 'CategoryListPage',
  components: {
    PageContainer,
    ArticleReward,
    ArrowRight
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const categories = ref([])
    const loading = ref(true)
    const error = ref('')

    const viewCategory = (categoryId) => {
      router.push(`/category/${categoryId}`)
    }

    // 获取随机分类样式
    const getCategoryStyle = () => {
      const colors = [
        { border: '#91d5ff', bg: '#e6f7ff' }, // 蓝色系
        { border: '#b7eb8f', bg: '#f6ffed' }, // 绿色系
        { border: '#ffd591', bg: '#fff7e6' }, // 橙色系
        { border: '#ffadd2', bg: '#fff0f6' }, // 粉色系
        { border: '#d3adf7', bg: '#f9f0ff' }, // 紫色系
        { border: '#87e8de', bg: '#e6fffb' }, // 青色系
        { border: '#ffa39e', bg: '#fff1f0' }, // 红色系
        { border: '#9254de', bg: '#f9f0ff' }  // 深紫色系
      ]
      const style = colors[Math.floor(Math.random() * colors.length)]
      return {
        borderLeft: `4px solid ${style.border}`,
        backgroundColor: style.bg
      }
    }

    const fetchData = async () => {
      try {
        loading.value = true
        error.value = ''
        await store.dispatch('fetchCategories')
        categories.value = store.state.categories
      } catch (err) {
        console.error('Error fetching categories:', err)
        error.value = '获取分类列表失败，请稍后重试'
        categories.value = []
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchData()
    })

    return {
      categories,
      loading,
      error,
      viewCategory,
      getCategoryStyle
    }
  }
}
</script>

<style lang="scss" scoped>
.category-page {
  .loading-wrapper {
    padding: 40px;
    background: white;
    border-radius: 8px;
  }

  .category-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    margin-bottom: 40px;  /* 添加底部间距，为打赏区域留出空间 */
    
    .category-card {
      transition: all 0.3s ease;
      border: none;
      overflow: hidden;
      border-radius: 8px;
      height: 100%;
      
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      }
      
      .category-content {
        padding: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        .category-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          
          h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: var(--el-color-primary);
            transition: color 0.3s ease;

            &:hover {
              color: var(--el-color-primary-light-3);
            }
          }
          
          .el-tag {
            font-weight: 500;
            background-color: var(--el-color-primary-light-9);
            border-color: var(--el-color-primary-light-5);
            color: var(--el-color-primary);
          }
        }
        
        .category-desc {
          flex: 1;
          margin: 0 0 20px;
          color: var(--el-text-color-regular);
          font-size: 15px;
          line-height: 1.8;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        
        .category-footer {
          text-align: right;
          
          .view-button {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            padding: 8px 16px;
            border-radius: 4px;
            background: var(--el-color-primary-light-9);
            color: var(--el-color-primary);
            border-color: var(--el-color-primary-light-5);
            transition: all 0.3s ease;
            
            .el-icon {
              transition: transform 0.3s ease;
            }
            
            &:hover {
              background: var(--el-color-primary);
              color: white;
              
              .el-icon {
                transform: translateX(4px);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .category-page {
    .category-list {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}

/* 打赏区域样式 */
.reward-section {
  margin: 40px 0;  /* 移除左右边距，只保留上下边距 */
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 0;  /* 移除圆角 */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.reward-title {
  font-size: 20px;
  color: #303133;
  margin-bottom: 12px;
}

.reward-description {
  color: #606266;
  margin-bottom: 20px;
  font-size: 14px;
}
</style> 