import axios from 'axios'
import { API_CONFIG, API_ENDPOINTS } from './config'
import { reactive } from 'vue'

// 创建axios实例
const api = axios.create(API_CONFIG)

// 存储当前活动的请求
const activeRequests = new Map()

// 取消之前的请求
const cancelPreviousRequest = (requestKey) => {
  if (activeRequests.has(requestKey)) {
    const controller = activeRequests.get(requestKey)
    controller.abort()
    activeRequests.delete(requestKey)
  }
}

// 创建响应式状态
export const state = reactive({
  articles: [],
  categories: [],
  tags: [],
  stats: {},
  currentArticle: null,
  currentCategory: null,
  currentTag: null
})

// API服务
export const apiService = {
  // 文章相关
  async getArticles(params = { page: 1, page_size: 10 }) {
    const requestKey = 'getArticles'
    cancelPreviousRequest(requestKey)
    const controller = new AbortController()
    activeRequests.set(requestKey, controller)

    try {
      const response = await api.get(API_ENDPOINTS.ARTICLES, { 
        params,
        signal: controller.signal 
      })
      activeRequests.delete(requestKey)
      return response
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('请求已取消')
      }
      throw error
    }
  },

  async getArticleById(id) {
    const requestKey = `getArticle_${id}`
    cancelPreviousRequest(requestKey)
    const controller = new AbortController()
    activeRequests.set(requestKey, controller)

    try {
      const response = await api.get(API_ENDPOINTS.ARTICLE_DETAIL(id), {
        signal: controller.signal
      })
      activeRequests.delete(requestKey)
      return response
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('请求已取消')
      }
      throw error
    }
  },

  async verifyArticlePassword(id, password) {
    await api.get(API_ENDPOINTS.CSRF)
    return api.post(API_ENDPOINTS.ARTICLE_PASSWORD_VERIFY(id), { password })
  },

  // 分类相关
  async getCategories() {
    const requestKey = 'getCategories'
    cancelPreviousRequest(requestKey)
    const controller = new AbortController()
    activeRequests.set(requestKey, controller)

    try {
      const response = await api.get(API_ENDPOINTS.CATEGORIES, {
        signal: controller.signal
      })
      activeRequests.delete(requestKey)
      return response
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('请求已取消')
      }
      throw error
    }
  },

  async getCategoryById(id) {
    const requestKey = `getCategory_${id}`
    cancelPreviousRequest(requestKey)
    const controller = new AbortController()
    activeRequests.set(requestKey, controller)

    try {
      const response = await api.get(API_ENDPOINTS.CATEGORY_DETAIL(id), {
        signal: controller.signal
      })
      activeRequests.delete(requestKey)
      return response
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('请求已取消')
      }
      throw error
    }
  },

  async getCategoryArticles(id, params = { page: 1, page_size: 10 }) {
    return api.get(API_ENDPOINTS.CATEGORY_ARTICLES(id), { params })
  },

  async createCategory(data) {
    return api.post(API_ENDPOINTS.CATEGORIES, data)
  },

  async updateCategory(id, data) {
    return api.put(`${API_ENDPOINTS.CATEGORIES}${id}/`, data)
  },

  async deleteCategory(id) {
    return api.delete(`${API_ENDPOINTS.CATEGORIES}${id}/`)
  },

  async manageCategoryOrder(data) {
    return api.post(API_ENDPOINTS.CATEGORY_MANAGE, data)
  },

  // 标签相关
  async getTags() {
    const requestKey = 'getTags'
    cancelPreviousRequest(requestKey)
    const controller = new AbortController()
    activeRequests.set(requestKey, controller)

    try {
      const response = await api.get(API_ENDPOINTS.TAGS, {
        signal: controller.signal
      })
      activeRequests.delete(requestKey)
      return response
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('请求已取消')
      }
      throw error
    }
  },

  async getTagById(id) {
    return api.get(API_ENDPOINTS.TAG_DETAIL(id))
  },

  async getTagArticles(id, params = { page: 1, page_size: 10 }) {
    return api.get(API_ENDPOINTS.TAG_ARTICLES(id), { params })
  },

  // 评论相关
  async getComments(articleId) {
    try {
      const response = await api.get(API_ENDPOINTS.ARTICLE_COMMENTS(articleId))
      console.log('Raw comments response:', response)
      // 确保返回的是数组
      if (Array.isArray(response.data)) {
        return { data: response.data }
      } else if (response.data && Array.isArray(response.data.results)) {
        return { data: response.data.results }
      } else {
        console.warn('Unexpected comments data format:', response.data)
        return { data: [] }
      }
    } catch (error) {
      console.error('Error in getComments:', error)
      if (error.response && error.response.status === 404) {
        return { data: [] }
      }
      throw error
    }
  },

  async createComment(data) {
    try {
      // 先获取CSRF令牌
      await api.get(API_ENDPOINTS.CSRF)
      const response = await api.post(API_ENDPOINTS.COMMENTS, data)
      return response
    } catch (error) {
      console.error('Error in createComment:', error)
      throw error
    }
  },

  // 文件上传
  async uploadFile(file, type = 'image') {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    return api.post(API_ENDPOINTS.UPLOAD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  // 统计相关
  async getBlogStats() {
    return api.get(API_ENDPOINTS.BLOG_STATS)
  },

  async getSystemStatus() {
    return api.get(API_ENDPOINTS.SYSTEM_STATUS)
  },

  async getStats() {
    return api.get(API_ENDPOINTS.STATS)
  },

  // 照片相关
  async getPhotoCategories() {
    return api.get(API_ENDPOINTS.PHOTO_CATEGORIES)
  },

  async getPhotoCategoryById(id) {
    return api.get(API_ENDPOINTS.PHOTO_CATEGORY_DETAIL(id))
  },

  async getPhotos(params) {
    return api.get(API_ENDPOINTS.PHOTOS, { params })
  },

  async verifyPhotoPassword(id, password) {
    await api.get(API_ENDPOINTS.CSRF)
    return api.post(API_ENDPOINTS.PHOTO_PASSWORD_VERIFY(id), { password })
  },

  // 音乐相关
  async getMusicCategories() {
    return api.get(API_ENDPOINTS.MUSIC_CATEGORIES)
  },

  async getMusic(params) {
    console.log('Requesting music with params:', params)
    const response = await api.get(API_ENDPOINTS.MUSIC, { params })
    console.log('Music API response:', response.data)
    return response
  },

  async verifyMusicCategoryPassword(id, password) {
    await api.get(API_ENDPOINTS.CSRF)
    return api.post(API_ENDPOINTS.MUSIC_PASSWORD_VERIFY(id), { category_id: id, password })
  },

  // 软件相关
  async getSoftwareCategories() {
    return api.get(API_ENDPOINTS.SOFTWARE_CATEGORIES)
  },

  async getSoftware(params) {
    return api.get(API_ENDPOINTS.SOFTWARE, { params })
  },

  async downloadSoftware(id, config = {}) {
    const downloadConfig = {
      ...config,
      responseType: 'blob',
      headers: {
        'Accept': '*/*',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
    return api.get(API_ENDPOINTS.SOFTWARE_DOWNLOAD(id), downloadConfig)
  },

  async verifySoftwareCategoryPassword(id, password) {
    await api.get(API_ENDPOINTS.CSRF)
    return api.post(API_ENDPOINTS.SOFTWARE_PASSWORD_VERIFY(id), { category_id: id, password })
  },

  // 收藏相关
  async getFavoriteCategories() {
    return api.get(API_ENDPOINTS.FAVORITE_CATEGORIES)
  },

  async getFavorites() {
    return api.get(API_ENDPOINTS.FAVORITES)
  },

  async getFavoritesByCategory(categoryId) {
    return api.get(`${API_ENDPOINTS.FAVORITES}?category=${categoryId}`)
  },

  async verifyFavoritePassword(id, password) {
    await api.get(API_ENDPOINTS.CSRF)
    return api.post(API_ENDPOINTS.FAVORITE_PASSWORD_VERIFY(id), { password })
  },

  // 视频相关
  async getVideoCategories() {
    return api.get(API_ENDPOINTS.VIDEO_CATEGORIES)
  },

  async getVideos(params) {
    return api.get(API_ENDPOINTS.VIDEO, { params })
  },

  async verifyVideoPassword(id, password) {
    await api.get(API_ENDPOINTS.CSRF)
    return api.post(API_ENDPOINTS.VIDEO_PASSWORD_VERIFY(id), { password })
  },

  // 其他
  async getAbout() {
    return api.get(API_ENDPOINTS.ABOUT)
  },

  async getLocation() {
    try {
      const response = await api.get(API_ENDPOINTS.LOCATION)
      return response
    } catch (error) {
      console.error('Error in getLocation:', error)
      // 如果获取位置失败，返回一个默认值
      return {
        data: {
          city: '未知',
          region: '未知'
        }
      }
    }
  },

  async post(url, data) {
    return api.post(url, data)
  },

  // 搜索文章
  searchArticles(query) {
    return api.get(API_ENDPOINTS.ARTICLE_SEARCH, {
      params: { q: query }
    })
  },

  // 在组件卸载时调用此方法取消所有请求
  cancelAllRequests() {
    activeRequests.forEach(controller => {
      controller.abort()
    })
    activeRequests.clear()
  }
}

export default apiService 