<template>
  <div class="nav-header">
    <div class="left">
      <div class="time">
        <el-icon><Clock /></el-icon>
        <span class="highlight">{{ currentTime }}</span>
      </div>
      <div class="date">
        <el-icon><Calendar /></el-icon>
        <span class="highlight">{{ currentDate }}</span>
      </div>
      <div class="location" v-if="location">
        <el-icon><Location /></el-icon>
        <span class="highlight">{{ location }}</span>
      </div>
    </div>
    
    <div class="search-container">
      <el-input
        v-model="searchQuery"
        placeholder="搜索文章..."
        class="search-input"
        :class="{ 'is-expanded': isSearchFocused }"
        @keyup.enter="handleSearch"
        @focus="handleSearchFocus"
        @blur="handleSearchBlur"
        clearable
      >
        <template #prefix>
          <el-icon><Search /></el-icon>
        </template>
      </el-input>
    </div>
    
    <div class="right">
      <div class="rss-feed">
        <el-tooltip 
          effect="dark" 
          placement="bottom"
          content="RSS订阅"
        >
          <div class="rss-item" @click="showRSSDialog = true">
            <el-icon><Connection /></el-icon>
            <span>RSS订阅</span>
          </div>
        </el-tooltip>
      </div>

      <!-- 添加RSS订阅对话框 -->
      <el-dialog
        v-model="showRSSDialog"
        title="RSS订阅"
        width="400px"
        align-center
      >
        <div class="rss-dialog-content">
          <h3>RSS订阅地址</h3>
          <div class="rss-url-box">
            <el-input
              v-model="rssUrl"
              readonly
              size="large"
            >
              <template #append>
                <el-button @click="copyRssUrl">
                  复制
                </el-button>
              </template>
            </el-input>
          </div>

          <h3>推荐的RSS阅读器</h3>
          <div class="rss-readers">
            <el-button-group>
              <el-button 
                type="primary" 
                :icon="Link"
                @click="openReader('https://feedly.com/i/subscription/feed/' + encodeURIComponent(rssUrl))"
              >
                使用 Feedly 订阅
              </el-button>
              <el-button 
                type="success" 
                :icon="Link"
                @click="openReader('https://inoreader.com/feed/' + encodeURIComponent(rssUrl))"
              >
                使用 InoReader 订阅
              </el-button>
            </el-button-group>
          </div>

          <div class="rss-tips">
            <h4>使用说明：</h4>
            <ol>
              <li>复制上方的RSS地址</li>
              <li>打开你喜欢的RSS阅读器</li>
              <li>添加订阅源时粘贴该地址</li>
              <li>或者直接点击上方的快捷订阅按钮</li>
            </ol>
          </div>
        </div>
      </el-dialog>

      <div class="stats">
        <el-tooltip 
          effect="dark" 
          placement="bottom"
        >
          <template #content>
            <div class="stats-tooltip">
              <p>文章：<span class="highlight">{{ stats.total_articles || 0 }}</span></p>
              <p>分类：<span class="highlight">{{ stats.total_categories || 0 }}</span></p>
              <p>标签：<span class="highlight">{{ stats.total_tags || 0 }}</span></p>
              <p>访问：<span class="highlight">{{ stats.total_views || 0 }}</span></p>
            </div>
          </template>
          <div class="stat-item">
            <el-icon><DataLine /></el-icon>
            <span>博客统计</span>
          </div>
        </el-tooltip>
      </div>
      
      <div class="system-status">
        <el-tooltip 
          effect="dark" 
          placement="bottom"
        >
          <template #content>
            <div class="status-tooltip">
              <p>运行时间：<span class="highlight">{{ uptime }}</span></p>
              <p>内存使用：<span class="highlight">{{ memoryUsage }}</span></p>
              <p>CPU使用：<span class="highlight">{{ cpuUsage }}</span></p>
            </div>
          </template>
          <div class="status-item">
            <el-icon><Monitor /></el-icon>
            <span>系统状态</span>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { 
  Clock, 
  Calendar, 
  DataLine, 
  Monitor,
  Location,
  Search,
  Connection,
  Link 
} from '@element-plus/icons-vue'
import apiService from '@/api/service'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

const router = useRouter()
const searchQuery = ref('')
const isSearchFocused = ref(false)

// 处理搜索
const handleSearch = () => {
  if (!searchQuery.value.trim()) {
    ElMessage.warning('请输入搜索关键词')
    return
  }
  
  // 跳转到搜索结果页面
  router.push({
    name: 'search',
    query: { q: searchQuery.value.trim() }
  })
  
  // 清空搜索框
  searchQuery.value = ''
}

// 处理搜索框获得焦点
const handleSearchFocus = () => {
  isSearchFocused.value = true
}

// 处理搜索框失去焦点
const handleSearchBlur = () => {
  if (!searchQuery.value) {
    isSearchFocused.value = false
  }
}

dayjs.extend(duration)

const currentTime = ref('')
const currentDate = ref('')
const uptime = ref('')
const memoryUsage = ref('0%')
const cpuUsage = ref('0%')
const stats = ref({})
const location = ref('')

// 更新时间
const updateTime = () => {
  const now = dayjs()
  currentTime.value = now.format('HH:mm:ss')
  currentDate.value = now.format('YYYY年MM月DD日 dddd')
}

// 更新运行时间
const startTime = dayjs()
const updateUptime = () => {
  const duration = dayjs.duration(dayjs().diff(startTime))
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  uptime.value = `${days}天${hours}时${minutes}分${seconds}秒`
}

// 获取系统状态
const fetchSystemStatus = async () => {
  try {
    const response = await apiService.getSystemStatus()
    const data = response.data
    memoryUsage.value = `${data.memory_usage}%`
    cpuUsage.value = `${data.cpu_usage}%`
  } catch (error) {
    console.error('Error fetching system status:', error)
    memoryUsage.value = '获取失败'
    cpuUsage.value = '获取失败'
  }
}

// 获取博客统计
const fetchStats = async () => {
  try {
    const response = await apiService.getStats()
    stats.value = response.data
  } catch (error) {
    console.error('Error fetching stats:', error)
    ElMessage.error('获取统计信息失败')
  }
}

// 获取用户位置信息
const fetchLocation = async () => {
  try {
    const response = await apiService.getLocation()
    const locationData = response.data
    console.log('Location data:', locationData)  // 调试日志

    if (locationData.country && locationData.region && locationData.city) {
      // 如果是中国，不显示国家名
      const isChina = locationData.country === '中国'
      const country = isChina ? '' : `${locationData.country} `
      
      // 如果省份和城市相同，只显示一个
      const region = locationData.region.replace(/省|市|自治区|维吾尔|壮族|回族|特别行政区/g, '')
      const city = locationData.city.replace(/市|区|县/g, '')
      
      if (region === city) {
        location.value = `${country}${city}`
      } else {
        location.value = `${country}${region} ${city}`
      }
    } else {
      console.warn('Incomplete location data:', locationData)
      location.value = '定位中...'
    }
  } catch (error) {
    console.error('Error fetching location:', error)
    location.value = '定位中...'
  }
}

// RSS相关
const showRSSDialog = ref(false)
const rssUrl = ref(window.location.origin + '/api/feed/rss/')

const copyRssUrl = () => {
  navigator.clipboard.writeText(rssUrl.value).then(() => {
    ElMessage.success('RSS地址已复制到剪贴板')
  }).catch(() => {
    ElMessage.error('复制失败，请手动复制')
  })
}

const openReader = (url) => {
  window.open(url, '_blank')
}

// 定时器
let timeInterval
let uptimeInterval
let statusInterval

onMounted(() => {
  // 初始化
  updateTime()
  updateUptime()
  fetchSystemStatus()
  fetchStats()
  fetchLocation()
  
  // 设置定时器
  timeInterval = setInterval(updateTime, 1000)
  uptimeInterval = setInterval(updateUptime, 1000)
  statusInterval = setInterval(() => {
    fetchSystemStatus()
    fetchStats()
  }, 60000) // 每分钟更新一次状态
})

onUnmounted(() => {
  // 清理定时器
  clearInterval(timeInterval)
  clearInterval(uptimeInterval)
  clearInterval(statusInterval)
})
</script>

<style lang="scss" scoped>
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  
  .left {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .right {
    display: flex;
    align-items: center;
    gap: 20px;
    
    .rss-feed {
      .rss-item {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 4px;
        transition: all 0.3s ease;
        
        &:hover {
          background: var(--el-color-primary-light-9);
          color: var(--el-color-primary);
        }
        
        .el-icon {
          font-size: 16px;
        }
        
        span {
          font-size: 14px;
        }
      }
    }
  }
  
  .time, .date, .location, .stat-item, .status-item {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #606266;
    font-size: 14px;
    
    .el-icon {
      font-size: 16px;
    }

    .highlight {
      font-weight: 600;
      color: #409EFF;
    }
  }
  
  .stats-tooltip, .status-tooltip {
    p {
      margin: 5px 0;
      white-space: nowrap;
      font-size: 13px;

      .highlight {
        font-weight: 600;
        color: #409EFF;
      }
    }
  }
  
  .stat-item, .status-item {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
    
    &:hover {
      background-color: #f5f7fa;
    }
  }
  
  .search-container {
    flex: 0;
    max-width: 400px;
    margin: 0 0 0 auto;
    padding-right: 20px;
    transition: flex 0.3s ease;
    display: flex;
    justify-content: flex-end;
    
    .search-input {
      width: 180px;
      transition: all 0.3s ease;
      transform-origin: right center;
      
      &.is-expanded {
        width: 300px;
      }
      
      :deep(.el-input__wrapper) {
        border-radius: 20px;
        box-shadow: 0 0 0 1px var(--el-border-color) inset;
        transition: all 0.3s ease;
        
        &:hover {
          box-shadow: 0 0 0 1px var(--el-color-primary) inset;
        }
        
        &.is-focus {
          box-shadow: 0 0 0 1px var(--el-color-primary) inset;
        }
      }
      
      :deep(.el-input__inner) {
        font-size: 14px;
        height: 36px;
        padding-left: 40px;
        transition: all 0.3s ease;
      }
      
      :deep(.el-input__prefix) {
        font-size: 16px;
        left: 12px;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav-header {
    padding: 8px 12px;
    flex-wrap: nowrap;
    
    .left {
      gap: 12px;
      flex: 1;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      
      .time, .date, .location {
        font-size: 12px;
        white-space: nowrap;
        flex-shrink: 0;
        
        .el-icon {
          font-size: 14px;
        }
        
        .highlight {
          font-size: 12px;
        }
      }
      
      .date {
        display: none;
      }
    }
    
    .right {
      gap: 8px;
      
      .stat-item, .status-item {
        padding: 4px 8px;
        font-size: 12px;
        
        .el-icon {
          font-size: 14px;
        }
        
        span {
          display: none;
        }
      }
    }
    
    .search-container {
      margin: 0 10px;
      
      .search-input {
        width: 140px;
        
        &.is-expanded {
          width: 220px;
        }
      }
    }
  }
  
  .stats-tooltip, .status-tooltip {
    p {
      font-size: 12px;
    }
  }
}

@media (max-width: 480px) {
  .nav-header {
    padding: 6px 10px;
    
    .left {
      gap: 8px;
      
      .time, .location {
        font-size: 11px;
        
        .el-icon {
          font-size: 13px;
        }
        
        .highlight {
          font-size: 11px;
        }
      }
    }
    
    .right {
      gap: 6px;
      
      .stat-item, .status-item {
        padding: 3px 6px;
      }
    }
    
    .search-container {
      margin: 0 6px;
      
      .search-input {
        width: 120px;
        
        &.is-expanded {
          width: 180px;
        }
      }
    }
  }
}

.rss-dialog-content {
  padding: 20px;

  h3 {
    margin: 0 0 15px;
    font-size: 16px;
    color: #303133;
  }

  .rss-url-box {
    margin-bottom: 25px;
  }

  .rss-readers {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .rss-tips {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;

    h4 {
      margin: 0 0 10px;
      font-size: 14px;
      color: #303133;
    }

    ol {
      margin: 0;
      padding-left: 20px;
      
      li {
        color: #606266;
        font-size: 14px;
        line-height: 1.8;
      }
    }
  }
}

@media (max-width: 768px) {
  .rss-dialog-content {
    .rss-readers {
      flex-direction: column;
      
      .el-button-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
        
        .el-button {
          width: 100%;
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style> 