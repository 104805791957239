<template>
  <el-container class="app-container">
    <el-container>
      <el-aside width="200px" :class="{ 'is-mobile-open': isMobileMenuOpen }">
        <div class="logo">
          <h1>我的博客</h1>
        </div>
        <el-menu
          :default-active="$route.path"
          router
        >
          <el-menu-item index="/">
            <el-icon><HomeFilled /></el-icon>
            <span>首页</span>
          </el-menu-item>
          <el-menu-item index="/category">
            <el-icon><Menu /></el-icon>
            <span>分类</span>
          </el-menu-item>
          <el-menu-item index="/tag">
            <el-icon><Collection /></el-icon>
            <span>标签</span>
          </el-menu-item>
          <el-menu-item index="/photos">
            <el-icon><Picture /></el-icon>
            <span>相册</span>
          </el-menu-item>
          <el-menu-item index="/music">
            <el-icon><Headset /></el-icon>
            <span>音乐</span>
          </el-menu-item>
          <el-menu-item index="/videos">
            <el-icon><VideoCamera /></el-icon>
            <span>视频</span>
          </el-menu-item>
          <el-menu-item index="/software">
            <el-icon><Download /></el-icon>
            <span>软件</span>
          </el-menu-item>
          <el-menu-item index="/favorite">
            <el-icon><Star /></el-icon>
            <span>收藏</span>
          </el-menu-item>
          <el-menu-item index="/about">
            <el-icon><InfoFilled /></el-icon>
            <span>关于</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header height="auto" class="main-header">
          <el-button
            v-if="isMobile"
            @click="toggleMobileMenu"
            text
            class="mobile-menu-btn"
          >
            <el-icon size="24px"><IconMenu /></el-icon>
          </el-button>
          <NavHeader />
        </el-header>
        <el-main>
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <div class="view-container">
                <component :is="Component" />
              </div>
            </transition>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
    <GlobalMusicPlayer />
    <div 
      v-if="isMobile" 
      class="mobile-menu-overlay"
      :class="{ 'is-visible': isMobileMenuOpen }"
      @click="toggleMobileMenu"
    ></div>
  </el-container>
</template>

<script>
import {
  HomeFilled,
  Menu,
  Collection,
  Headset,
  Picture,
  Download,
  Star,
  InfoFilled,
  Menu as IconMenu,
  VideoCamera
} from '@element-plus/icons-vue'
import { createMusicStore } from '@/stores/musicStore'
import { provide, ref, onMounted, onUnmounted } from 'vue'
import GlobalMusicPlayer from '@/components/GlobalMusicPlayer.vue'
import NavHeader from '@/components/NavHeader.vue'

export default {
  name: 'App',
  components: {
    HomeFilled,
    Menu,
    Collection,
    Headset,
    Picture,
    Download,
    Star,
    InfoFilled,
    GlobalMusicPlayer,
    NavHeader,
    IconMenu,
    VideoCamera
  },
  setup() {
    const musicStore = createMusicStore()
    provide('musicStore', musicStore)

    const isMobileMenuOpen = ref(false)
    const isMobile = ref(window.innerWidth <= 768)

    // 监听窗口大小变化
    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768
      if (!isMobile.value) {
        isMobileMenuOpen.value = false
      }
    }

    // 切换移动端菜单
    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      isMobileMenuOpen,
      isMobile,
      toggleMobileMenu
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  min-height: 100vh;
  padding-bottom: 80px;
  
  .el-container {
    height: 100vh;
  }

  .el-aside {
    background-color: #304156;
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    
    .logo {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      h1 {
        color: #fff;
        margin: 0;
        font-size: 20px;
      }
    }
    
    .el-menu {
      border-right: none;
      background-color: transparent;
      
      .el-menu-item {
        color: #bfcbd9;
        
        &:hover, &.is-active {
          color: #fff;
          background-color: #263445;
        }
        
        .el-icon {
          color: inherit;
        }
      }
    }
  }

  .main-header {
    padding: 0;
    background: white;
    position: fixed;
    left: 200px;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  .el-main {
    padding: 20px;
    background-color: #f0f2f5;
    margin-left: 200px;
    margin-top: 50px;
    min-height: calc(100vh - 50px);
  }

  .view-container {
    height: 100%;
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// 响应式布局
@media (max-width: 768px) {
  .app-container {
    .el-aside {
      width: 0 !important;
      transform: translateX(-100%);
      transition: transform 0.3s ease;

      &.is-mobile-open {
        width: 200px !important;
        transform: translateX(0);
      }
    }

    .main-header {
      left: 0;
    }

    .el-main {
      margin-left: 0;
      padding: 16px;
    }
  }
}

// 移动端菜单按钮样式
.mobile-menu-btn {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .el-icon {
    color: var(--el-text-color-primary);
  }
}

// 遮罩层
.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;

  &.is-visible {
    display: block;
  }
}
</style>
