<template>
  <page-container title="我的收藏" subtitle="收藏有趣的网站和资源">
    <!-- 加载状态 -->
    <div v-if="loading" class="loading-container">
      <el-skeleton :rows="3" animated>
        <template #template>
          <div style="padding: 20px">
            <el-skeleton-item variant="p" style="width: 100%; height: 40px; margin-bottom: 20px" />
            <el-row :gutter="24">
              <el-col :span="6" v-for="i in 4" :key="i">
                <div style="padding: 20px">
                  <el-skeleton-item variant="image" style="width: 100%; height: 120px" />
                  <el-skeleton-item variant="h3" style="width: 50%; margin: 10px 0" />
                  <el-skeleton-item variant="text" style="width: 100%" />
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-skeleton>
    </div>

    <!-- 分类和收藏列表 -->
    <div v-else class="favorites-content">
      <!-- 分类标签页 -->
      <div class="tabs-header">
        <div 
          class="tab-item"
          :class="{ active: activeCategory === null }"
          @click="handleCategoryChange({ id: null, name: '全部收藏' })"
        >
          全部收藏
        </div>
        <div 
          v-for="category in categories" 
          :key="category.id"
          class="tab-item"
          :class="{ active: activeCategory === category.id }"
          @click="handleCategoryChange(category)"
        >
          {{ category.name }}
          <el-icon v-if="category.is_protected" class="lock-icon"><Lock /></el-icon>
        </div>
      </div>

      <div class="divider"></div>

      <!-- 密码验证表单 -->
      <div v-if="currentCategory && needPassword(currentCategory)" class="password-form">
        <el-form @submit.prevent="verifyPassword" class="verify-form">
          <div class="verify-content">
            <el-input
              v-model="passwordInput"
              type="password"
              placeholder="请输入访问密码"
              style="width: 200px; margin-right: 10px;"
            >
              <template #prefix>
                <el-icon><Lock /></el-icon>
              </template>
            </el-input>
            <el-button type="primary" @click="verifyPassword">
              验证
            </el-button>
          </div>
        </el-form>
      </div>

      <!-- 收藏列表 -->
      <div v-else-if="favoriteList && favoriteList.length > 0" class="favorites-grid">
        <el-row :gutter="24">
          <el-col 
            v-for="favorite in favoriteList" 
            :key="favorite.id"
            :xs="24" 
            :sm="12" 
            :md="8"
            :lg="6"
          >
            <div class="favorite-card-wrapper">
              <el-card 
                class="favorite-card" 
                shadow="hover"
                @click="openUrl(favorite.url)"
                :style="{
                  borderLeft: `4px solid ${favorite.style.border}`,
                  backgroundColor: favorite.style.bg
                }"
              >
                <div class="favorite-content">
                  <div class="favorite-icon">
                    <img 
                      :src="favoriteIcons.get(extractDomain(favorite.url)) || DEFAULT_ICON"
                      :alt="favorite.title"
                      class="site-icon"
                      @error="$event.target.src = DEFAULT_ICON"
                    >
                  </div>
                  <div class="favorite-main">
                    <div class="title-section">
                      <h3 class="highlight-title">
                        {{ favorite.title }}
                      </h3>
                    </div>
                    <div class="desc-text">
                      {{ favorite.showFullDesc ? favorite.description : (favorite.description || '').slice(0, 50) }}
                      <span v-if="favorite.description && favorite.description.length > 50" class="show-more">
                        <a 
                          class="show-more-btn"
                          @click.stop="toggleDescription(favorite)"
                        >
                          {{ favorite.showFullDesc ? '收起' : '展开' }}
                        </a>
                      </span>
                    </div>
                    <div class="favorite-meta">
                      <span class="domain-text">{{ extractDomain(favorite.url) }}</span>
                      <span class="time-text">{{ formatDate(favorite.created_time) }}</span>
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 空状态 -->
      <el-empty 
        v-else 
        class="empty-state"
        description="暂无收藏内容"
      >
        <template #image>
          <div class="empty-icon-wrapper">
            <el-icon class="empty-icon"><Star /></el-icon>
          </div>
        </template>
      </el-empty>

      <!-- 添加打赏区域 -->
      <div class="reward-section">
        <h3 class="reward-title">支持创作</h3>
        <p class="reward-description">如果这些内容对您有帮助，欢迎打赏支持！</p>
        <article-reward />
      </div>
    </div>

    <!-- 全文描述对话框 -->
    <el-dialog
      v-model="dialogVisible"
      title="详细描述"
      width="50%"
      destroy-on-close
    >
      <div class="full-description">{{ currentDescription }}</div>
    </el-dialog>
  </page-container>
</template>

<script>
import { ref, onMounted } from 'vue'
import dayjs from 'dayjs'
import { Star, Lock } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import apiService from '@/api/service'
import PageContainer from '@/components/PageContainer.vue'
import ArticleReward from '@/components/Reward.vue'
import { getFaviconUrl } from '@/utils/favicon'

// 默认图标
const DEFAULT_ICON = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBkPSJNMTkgMjFINWEyIDIgMCAwIDEtMi0yVjVhMiAyIDAgMCAxIDItMmgxMWw1IDV2MTFhMiAyIDAgMCAxLTIgMnoiPjwvcGF0aD48cG9seWxpbmUgcG9pbnRzPSIxNyAyMSAxNyAxMyA3IDEzIDcgMjEiPjwvcG9seWxpbmU+PHBvbHlsaW5lIHBvaW50cz0iNyAzIDcgOCAxNSA4Ij48L3BvbHlsaW5lPjwvc3ZnPg=='

export default {
  name: 'FavoritePage',
  components: {
    Star,
    Lock,
    PageContainer,
    ArticleReward
  },
  setup() {
    const loading = ref(true)
    const categories = ref(null)
    const favoriteList = ref(null)
    const activeCategory = ref(null)
    const passwordInput = ref('')
    const verifiedCategories = ref(new Set())
    const dialogVisible = ref(false)
    const currentDescription = ref('')
    const currentCategory = ref(null)
    const categoryCountMap = ref(new Map())
    const favoriteIcons = ref(new Map())
    
    const formatDate = (date) => {
      return dayjs(date).format('YYYY-MM-DD')
    }

    const extractDomain = (url) => {
      try {
        const domain = new URL(url).hostname
        return domain.replace(/^www\./, '')
      } catch {
        return url
      }
    }

    const openUrl = (url) => {
      if (url) {
        window.open(url, '_blank')
      }
    }

    // 获取分类列表
    const fetchCategories = async () => {
      try {
        loading.value = true
        console.log('Fetching favorite categories...')
        const response = await apiService.getFavoriteCategories()
        console.log('Response:', response)
        
        if (Array.isArray(response.data)) {
          categories.value = response.data
          console.log('Categories:', categories.value)
        } else if (response.data.results && Array.isArray(response.data.results)) {
          categories.value = response.data.results
          console.log('Categories from results:', categories.value)
        } else {
          console.error('Unexpected data format:', response.data)
          categories.value = []
        }
        
        // 设置"全部收藏"为默认激活状态
        activeCategory.value = null
        currentCategory.value = null
        await fetchFavoriteList()
      } catch (error) {
        console.error('Error fetching categories:', error)
        console.error('Error response:', error.response?.data)
        ElMessage.error('获取收藏分类失败')
        categories.value = []
      } finally {
        loading.value = false
      }
    }

    // 获取边框颜色和背景色
    const getBorderColor = () => {
      const colors = [
        { border: '#91d5ff', bg: '#e6f7ff' }, // 蓝色系
        { border: '#b7eb8f', bg: '#f6ffed' }, // 绿色系
        { border: '#ffd591', bg: '#fff7e6' }, // 橙色系
        { border: '#ffadd2', bg: '#fff0f6' }, // 粉色系
        { border: '#d3adf7', bg: '#f9f0ff' }, // 紫色系
        { border: '#87e8de', bg: '#e6fffb' }, // 青色系
        { border: '#ffa39e', bg: '#fff1f0' }, // 红色系
        { border: '#9254de', bg: '#f9f0ff' }  // 深紫色系
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    }

    // 获取收藏列表
    const fetchFavoriteList = async () => {
      try {
        loading.value = true;
        let response;
        
        if (activeCategory.value === null) {
          // 获取全部收藏
          response = await apiService.getFavorites();
        } else {
          // 获取特定分类的收藏
          if (needPassword(currentCategory.value) && !verifiedCategories.value.has(activeCategory.value)) {
            favoriteList.value = [];
            loading.value = false;
            return;
          }
          response = await apiService.getFavoritesByCategory(activeCategory.value);
        }

        // 处理响应数据
        let favorites = [];
        if (Array.isArray(response.data)) {
          favorites = response.data;
        } else if (response.data.results && Array.isArray(response.data.results)) {
          favorites = response.data.results;
        }

        // 更新收藏列表
        favoriteList.value = favorites.map(item => {
          const style = getBorderColor();
          return {
            ...item,
            showFullDesc: false,
            style: {
              border: style.border,
              bg: style.bg
            }
          };
        });

        // 批量加载图标，使用Promise.all但限制并发数
        if (favoriteList.value && favoriteList.value.length > 0) {
          const domains = favoriteList.value.map(favorite => extractDomain(favorite.url));
          const MAX_CONCURRENT = 5; // 最大并发数
          
          // 分批处理域名
          for (let i = 0; i < domains.length; i += MAX_CONCURRENT) {
            const batch = domains.slice(i, i + MAX_CONCURRENT);
            await Promise.all(
              batch.map(domain => {
                if (!favoriteIcons.value.has(domain)) {
                  return loadFaviconUrl(domain).catch(() => {
                    console.warn(`Failed to load favicon for ${domain}`);
                    return DEFAULT_ICON;
                  });
                }
                return Promise.resolve(favoriteIcons.value.get(domain));
              })
            );
          }
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          ElMessage.error(error.response.data?.message || '获取收藏列表失败');
        } else if (error.request) {
          console.error('No response received:', error.request);
          ElMessage.error('服务器无响应，请稍后重试');
        } else {
          console.error('Request error:', error.message);
          ElMessage.error('获取收藏列表失败，请稍后重试');
        }
        favoriteList.value = [];
      } finally {
        loading.value = false;
      }
    };

    // 检查是否需要密码验证
    const needPassword = (category) => {
      return category && category.is_protected && !verifiedCategories.value.has(category.id)
    }

    // 验证密码
    const verifyPassword = async () => {
      if (!passwordInput.value) {
        ElMessage.warning('请输入密码')
        return
      }

      try {
        const response = await apiService.verifyFavoritePassword(
          currentCategory.value.id,
          passwordInput.value
        )

        if (response.data && response.data.status === 'success') {
          ElMessage.success('密码验证成功')
          verifiedCategories.value.add(currentCategory.value.id)
          passwordInput.value = ''
          
          // 如果当前在全部收藏页面
          if (activeCategory.value === null) {
            await fetchFavoriteList()
          } else {
            // 如果在特定分类页面
            await fetchFavoriteList(currentCategory.value.id)
          }
        } else {
          ElMessage.error(response.data?.message || '密码错误')
        }
      } catch (error) {
        console.error('Password verification error:', error)
        ElMessage.error('密码验证失败')
      }
    }

    // 处理分类切换
    const handleCategoryChange = async (category) => {
      try {
        // 如果点击当前已激活的分类，不做处理
        if (activeCategory.value === category.id) {
          return;
        }

        // 更新状态
        activeCategory.value = category.id;
        currentCategory.value = category;
        
        // 重新获取收藏列表
        await fetchFavoriteList();
      } catch (error) {
        console.error('Error changing category:', error);
        ElMessage.error('切换分类失败，请重试');
      }
    };

    const toggleDescription = (favorite) => {
      favorite.showFullDesc = !favorite.showFullDesc;
    }

    // 修改获取分类数量的函数
    const getCategoryCount = (categoryId) => {
      return categoryCountMap.value.get(categoryId) || '?'
    }

    const loadFaviconUrl = async (domain) => {
      // 如果已经加载过该域名的图标，直接返回缓存的结果
      if (favoriteIcons.value.has(domain)) {
        return favoriteIcons.value.get(domain);
      }

      try {
        const iconUrl = await getFaviconUrl(domain);
        favoriteIcons.value.set(domain, iconUrl);
        return iconUrl;
      } catch (error) {
        console.error('Error loading favicon:', error);
        favoriteIcons.value.set(domain, DEFAULT_ICON);
        return DEFAULT_ICON;
      }
    }

    onMounted(() => {
      fetchCategories()
    })

    return {
      loading,
      categories,
      favoriteList,
      activeCategory,
      handleCategoryChange,
      formatDate,
      openUrl,
      passwordInput,
      needPassword,
      verifyPassword,
      dialogVisible,
      currentDescription,
      toggleDescription,
      extractDomain,
      getCategoryCount,
      currentCategory,
      getBorderColor,
      categoryCountMap,
      favoriteIcons,
      loadFaviconUrl,
      DEFAULT_ICON
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  padding: 20px;
  background-color: var(--el-bg-color);
  border-radius: 8px;
}

.favorites-content {
  background-color: var(--el-bg-color);
  border-radius: 8px;
  overflow: hidden;
}

.tabs-header {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  background-color: var(--el-bg-color);
  border-radius: 8px 8px 0 0;
  
  .tab-item {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
    background-color: var(--el-color-info-light-9);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    
    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
    
    .lock-icon {
      color: var(--el-color-primary);
      font-size: 16px;
      margin-left: 5px;
    }
    
    .asset-count {
      font-size: 14px;
      color: var(--el-text-color-secondary);
    }
    
    &.active {
      background-color: var(--el-color-primary);
      color: white;
      
      .lock-icon {
        color: white;
      }
      
      .asset-count {
        color: var(--el-color-white);
        opacity: 0.8;
      }
    }
  }
}

.divider {
  height: 1px;
  background-color: var(--el-border-color-light);
  margin: 0 20px;
}

.password-form {
  padding: 30px;
  margin: 20px;
  text-align: center;
  background-color: var(--el-color-primary-light-9);
  border-radius: 12px;
  border: 1px solid var(--el-color-primary-light-7);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  
  .verify-form {
    .verify-content {
      display: inline-block;
      padding: 20px 30px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

      .el-input {
        margin-right: 15px;
        width: 250px;
      }

      .el-button {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}

.favorites-grid {
  padding: 20px;
  
  .favorite-card-wrapper {
    margin-bottom: 20px;
    
    .favorite-card {
      height: 100%;
      transition: all 0.3s;
      cursor: pointer;
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: var(--el-box-shadow-light);
      }
      
      .favorite-content {
        display: flex;
        gap: 10px;
        min-height: 130px;
        padding: 12px;
        
        .favorite-icon {
          flex-shrink: 0;
          width: 28px;
          height: 28px;
          
          .site-icon {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        
        .favorite-main {
          flex: 1;
          min-width: 0;
          display: flex;
          flex-direction: column;
          
          .highlight-title {
            margin: 0 0 6px;
            font-size: 15px;
            font-weight: 600;
            color: var(--el-text-color-primary);
            line-height: 1.4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          
          .desc-text {
            margin: 0 0 6px;
            font-size: 13px;
            color: var(--el-text-color-regular);
            line-height: 1.5;
            flex: 1;
            min-height: 38px;
            overflow: hidden;
            position: relative;
            word-break: break-all;
            
            .show-more {
              margin-left: 4px;
              
              .show-more-btn {
                color: var(--el-color-primary);
                cursor: pointer;
                
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          
          .favorite-meta {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: var(--el-text-color-secondary);
            margin-top: auto;
            
            .domain-text {
              color: var(--el-color-info);
            }
            
            .time-text {
              color: var(--el-text-color-secondary);
            }
          }
        }
      }
    }
  }
}

.empty-state {
  padding: 40px;
  
  .empty-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin: 0 auto 16px;
    border-radius: 50%;
    background: var(--el-color-primary-light-9);
    
    .empty-icon {
      font-size: 32px;
      color: var(--el-color-primary);
    }
  }
}

.full-description {
  padding: 20px;
  font-size: 14px;
  line-height: 1.8;
  color: var(--el-text-color-regular);
  white-space: pre-wrap;
}

// 响应式设计
@media (max-width: 768px) {
  .favorites-grid {
    padding: 16px;
    
    .favorite-card-wrapper {
      margin-bottom: 16px;
    }
  }
}

@media (min-width: 1400px) {
  .favorites-grid {
    padding: 32px;
  }
}

/* 打赏区域样式 */
.reward-section {
  margin: 40px 20px;
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 0;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.reward-title {
  font-size: 20px;
  color: #303133;
  margin-bottom: 12px;
}

.reward-description {
  color: #606266;
  margin-bottom: 20px;
  font-size: 14px;
}

/* 添加默认图标样式 */
.site-icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
</style> 