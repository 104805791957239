<template>
  <page-container :title="category?.name" :subtitle="category?.description">
    <div class="category-detail">
      <!-- 加载状态 -->
      <div v-if="loading" class="loading-state">
        <el-skeleton :rows="3" animated />
      </div>
      
      <!-- 文章列表 -->
      <div v-else class="article-list">
        <div v-for="article in articles" :key="article.id" class="article-item">
          <el-card 
            class="article-card" 
            shadow="hover"
            :body-style="{ padding: '0px' }"
          >
            <div class="article-content" :style="getArticleStyle()">
              <div class="article-main">
                <div class="article-header">
                  <router-link 
                    :to="`/article/${article.id}`"
                    class="article-title"
                  >
                    {{ article.title }}
                    <el-icon v-if="article.is_protected" class="lock-icon"><Lock /></el-icon>
                  </router-link>
                  <div class="article-meta">
                    <span class="meta-item">
                      <el-icon><Calendar /></el-icon>
                      {{ formatDate(article.created_time) }}
                    </span>
                    <span class="meta-item">
                      <el-icon><View /></el-icon>
                      {{ article.views }} 阅读
                    </span>
                  </div>
                </div>
                
                <div class="article-excerpt">
                  {{ article.excerpt || '暂无摘要' }}
                </div>
                
                <div class="article-footer">
                  <div class="article-tags">
                    <router-link
                      v-for="tag in article.tags"
                      :key="tag.id"
                      :to="`/tag/${tag.id}`"
                      class="tag-link"
                    >
                      # {{ tag.name }}
                    </router-link>
                  </div>
                  <el-button 
                    type="primary" 
                    text
                    class="read-more"
                    @click="$router.push(`/article/${article.id}`)"
                  >
                    阅读全文
                    <el-icon class="el-icon--right"><ArrowRight /></el-icon>
                  </el-button>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        
        <!-- 空状态 -->
        <el-empty 
          v-if="articles.length === 0" 
          description="该分类下暂无文章"
        />

        <!-- 分页器 -->
        <div class="pagination-container" v-if="total > 0">
          <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10, 20, 30, 50]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
          />
        </div>
      </div>
    </div>
  </page-container>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { Calendar, View, ArrowRight, Lock } from '@element-plus/icons-vue'
import PageContainer from '@/components/PageContainer.vue'
import apiService from '@/api/service'

const route = useRoute()
const category = ref(null)
const articles = ref([])
const loading = ref(true)
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(0)

const formatDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

// 获取随机文章样式
const getArticleStyle = () => {
  const colors = [
    { border: '#91d5ff', bg: '#e6f7ff' }, // 蓝色系
    { border: '#b7eb8f', bg: '#f6ffed' }, // 绿色系
    { border: '#ffd591', bg: '#fff7e6' }, // 橙色系
    { border: '#ffadd2', bg: '#fff0f6' }, // 粉色系
    { border: '#d3adf7', bg: '#f9f0ff' }, // 紫色系
    { border: '#87e8de', bg: '#e6fffb' }, // 青色系
    { border: '#ffa39e', bg: '#fff1f0' }, // 红色系
    { border: '#9254de', bg: '#f9f0ff' }  // 深紫色系
  ]
  const style = colors[Math.floor(Math.random() * colors.length)]
  return {
    borderLeft: `4px solid ${style.border}`,
    backgroundColor: style.bg
  }
}

const fetchData = async () => {
  try {
    loading.value = true
    const categoryId = route.params.id
    
    // 获取分类信息
    const categoryResponse = await apiService.getCategoryById(categoryId)
    category.value = categoryResponse.data
    
    // 获取分类文章列表
    const articlesResponse = await apiService.getCategoryArticles(categoryId, {
      page: currentPage.value,
      page_size: pageSize.value
    })
    articles.value = articlesResponse.data.results
    total.value = articlesResponse.data.count
  } catch (error) {
    console.error('Error fetching category data:', error)
  } finally {
    loading.value = false
  }
}

// 处理页码变化
const handlePageChange = (page) => {
  currentPage.value = page
  fetchData()
}

// 处理每页条数变化
const handleSizeChange = (size) => {
  pageSize.value = size
  currentPage.value = 1
  fetchData()
}

onMounted(fetchData)

// 组件卸载前取消所有请求
onBeforeUnmount(() => {
  apiService.cancelAllRequests()
})
</script>

<style lang="scss" scoped>
.category-detail {
  .loading-state {
    padding: 40px;
    background: white;
    border-radius: 8px;
  }

  .article-list {
    .article-item {
      margin-bottom: 24px;
      
      .article-card {
        transition: all 0.3s ease;
        border: none;
        overflow: hidden;
        border-radius: 8px;
        
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }
        
        .article-content {
          padding: 24px;
          
          .article-main {
            .article-header {
              margin-bottom: 16px;
              
              .article-title {
                display: block;
                font-size: 20px;
                font-weight: 600;
                color: #303133;
                text-decoration: none;
                margin-bottom: 12px;
                transition: all 0.3s ease;
                
                &:hover {
                  color: var(--el-color-primary);
                }
                
                .lock-icon {
                  margin-left: 8px;
                  font-size: 16px;
                  color: #909399;
                  vertical-align: middle;
                }
              }
              
              .article-meta {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;
                color: #909399;
                font-size: 14px;
                
                .meta-item {
                  display: inline-flex;
                  align-items: center;
                  gap: 4px;
                  
                  .el-icon {
                    font-size: 16px;
                  }
                }
              }
            }
            
            .article-excerpt {
              color: #606266;
              font-size: 15px;
              line-height: 1.8;
              margin: 16px 0;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
            
            .article-footer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;
              
              .article-tags {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                
                .tag-link {
                  color: #606266;
                  text-decoration: none;
                  font-size: 14px;
                  transition: all 0.3s ease;
                  background: rgba(255, 255, 255, 0.8);
                  padding: 4px 8px;
                  border-radius: 4px;
                  
                  &:hover {
                    color: var(--el-color-primary);
                    background: var(--el-color-primary-light-9);
                  }
                }
              }
              
              .read-more {
                font-size: 14px;
                display: inline-flex;
                align-items: center;
                gap: 4px;
                padding: 8px 16px;
                border-radius: 4px;
                background: rgba(255, 255, 255, 0.8);
                
                .el-icon {
                  transition: transform 0.3s ease;
                }
                
                &:hover {
                  .el-icon {
                    transform: translateX(4px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.pagination-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .category-detail {
    .article-list {
      .article-item {
        .article-card {
          .article-content {
            .article-main {
              .article-meta {
                flex-direction: column;
                gap: 8px;
              }
              
              .article-footer {
                flex-direction: column;
                gap: 16px;
                align-items: flex-start;
                
                .article-tags {
                  margin-bottom: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .pagination-container {
    .el-pagination {
      font-size: 12px;
      
      .el-pagination__sizes {
        display: none;
      }
    }
  }
}
</style> 