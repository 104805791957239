<template>
  <page-container title="关于我" subtitle="个人简介">
    <div v-if="loading" class="loading-state">
      <el-skeleton :rows="10" animated />
    </div>
    <div v-else-if="error" class="error-state">
      <el-empty :description="error">
        <template #image>
          <el-icon style="font-size: 48px;"><InfoFilled /></el-icon>
        </template>
      </el-empty>
    </div>
    <div v-else class="about-content">
      <div class="markdown-body" v-html="renderedContent"></div>
      <div class="reward-section">
        <h3 class="reward-title">支持创作</h3>
        <p class="reward-description">如果您觉得这些内容对您有帮助，欢迎打赏支持！</p>
        <article-reward />
      </div>
    </div>
  </page-container>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { InfoFilled } from '@element-plus/icons-vue'
import apiService from '@/api/service'
import { marked } from 'marked'
import PageContainer from '@/components/PageContainer.vue'
import ArticleReward from '@/components/Reward.vue'

export default {
  name: 'AboutPage',
  components: {
    InfoFilled,
    PageContainer,
    ArticleReward
  },
  setup() {
    const loading = ref(true)
    const error = ref(null)
    const content = ref('')

    const renderedContent = computed(() => {
      return marked(content.value || '')
    })

    const fetchAboutContent = async () => {
      try {
        loading.value = true
        error.value = null
        
        console.log('Fetching about content...')
        const response = await apiService.getAbout()
        console.log('Response:', response.data)
        
        if (response.data && response.data.length > 0) {
          content.value = response.data[0].content
        } else {
          error.value = '暂无内容'
          try {
            await apiService.post('/about/', {
              content: `# 关于我\n\n## 个人简介\n\n80后技术人，专注 Linux 运维领域十余载...`
            })
          } catch (postError) {
            console.error('Error creating default content:', postError)
          }
        }
      } catch (err) {
        console.error('Error details:', err.response || err)
        error.value = '获取内容失败'
        ElMessage.error('获取关于我的内容失败')
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchAboutContent()
    })

    return {
      loading,
      error,
      renderedContent
    }
  }
}
</script>

<style lang="scss" scoped>
.about-content {
  .loading-state {
    padding: 40px;
  }

  .error-state {
    padding: 60px 0;
    text-align: center;
    color: #909399;
  }

  .reward-section {
    margin: 40px -20px;
    text-align: center;
    padding: 30px;
    background: #f8f9fa;
    border-radius: 0;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }

  .reward-title {
    font-size: 20px;
    color: #303133;
    margin-bottom: 12px;
  }

  .reward-description {
    color: #606266;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .about-content {
    .markdown-body {
      font-size: 16px;
      line-height: 1.8;
      color: #2c3e50;

      h1, h2 {
        border-bottom: 1px solid #eaecef;
        padding-bottom: 0.3em;
        margin-top: 24px;
        margin-bottom: 16px;
      }

      h1 {
        font-size: 2em;
      }

      h2 {
        font-size: 1.5em;
      }

      ul {
        padding-left: 2em;
        margin: 16px 0;
      }

      blockquote {
        padding: 0 1em;
        color: #6a737d;
        border-left: 0.25em solid #dfe2e5;
        margin: 16px 0;
      }

      code {
        padding: 0.2em 0.4em;
        background-color: rgba(27,31,35,0.05);
        border-radius: 3px;
      }
    }
  }
}
</style> 