<template>
  <div class="article-list">
    <div v-for="article in articles" :key="article.id" class="article-item">
      <el-card>
        <template #header>
          <div class="article-header">
            <router-link 
              :to="`/article/${article.id}`"
              class="article-title"
            >
              {{ article.title }}
              <el-icon v-if="article.password" class="lock-icon"><Lock /></el-icon>
            </router-link>
            <div class="article-meta">
              <span>
                <el-icon><Calendar /></el-icon>
                {{ formatDate(article.created_time) }}
              </span>
              <span>
                <el-icon><View /></el-icon>
                {{ article.views }} 阅读
              </span>
              <span>
                <el-icon><Folder /></el-icon>
                <template v-if="article.categories && article.categories.length > 0">
                  <router-link 
                    v-for="(category, index) in article.categories" 
                    :key="category.id" 
                    :to="`/category/${category.id}`"
                  >
                    {{ category.name }}{{ index < article.categories.length - 1 ? ', ' : '' }}
                  </router-link>
                </template>
                <span v-else>未分类</span>
              </span>
            </div>
          </div>
        </template>
        <div class="article-excerpt">{{ article.excerpt || '暂无摘要' }}</div>
        <div class="article-footer">
          <div class="article-tags">
            <el-tag
              v-for="tag in article.tags"
              :key="tag.id"
              size="small"
              type="info"
              effect="plain"
              class="tag-item"
            >
              <router-link :to="{ name: 'TagDetail', params: { id: tag.id }}">
                {{ tag.name }}
              </router-link>
            </el-tag>
          </div>
          <el-button 
            type="primary" 
            text
            class="read-more"
            @click="$router.push(`/article/${article.id}`)"
          >
            阅读全文
            <el-icon class="el-icon--right"><ArrowRight /></el-icon>
          </el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { Calendar, View, Folder, ArrowRight, Lock } from '@element-plus/icons-vue'
import dayjs from 'dayjs'

export default {
  name: 'ArticleList',
  components: {
    Calendar,
    View,
    Folder,
    ArrowRight,
    Lock
  },
  props: {
    articles: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const formatDate = (date) => {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    }

    return {
      formatDate
    }
  }
}
</script>

<style lang="scss" scoped>
.article-list {
  .article-item {
    margin-bottom: 20px;

    .article-header {
      .article-title {
        display: block;
        font-size: 20px;
        font-weight: 600;
        color: #303133;
        text-decoration: none;
        margin-bottom: 10px;
        transition: color 0.3s;

        &:hover {
          color: var(--el-color-primary);
        }
      }

      .article-meta {
        color: #909399;
        font-size: 14px;

        span {
          margin-right: 20px;
          display: inline-flex;
          align-items: center;

          .el-icon {
            margin-right: 4px;
          }

          a {
            color: inherit;
            text-decoration: none;

            &:hover {
              color: var(--el-color-primary);
            }
          }
        }
      }
    }

    .article-excerpt {
      margin: 20px 0;
      color: #666;
      line-height: 1.6;
    }

    .article-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .article-tags {
        .tag-item {
          margin-right: 10px;

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }

      .read-more {
        .el-icon {
          transition: transform 0.3s;
        }

        &:hover {
          .el-icon {
            transform: translateX(3px);
          }
        }
      }
    }
  }
}
</style> 