<!-- 打赏组件 -->
<template>
  <div class="reward-container">
    <button class="reward-button" @click="showReward">
      <i class="el-icon-coffee-cup"></i> 打赏
    </button>

    <!-- 打赏弹窗 -->
    <el-dialog
      title="感谢支持"
      v-model="dialogVisible"
      width="300px"
      center
      custom-class="reward-dialog"
    >
      <div class="reward-content">
        <div class="reward-tabs">
          <div
            class="tab-item"
            :class="{ active: currentTab === 'wechat' }"
            @click="currentTab = 'wechat'"
          >
            微信支付
          </div>
          <div
            class="tab-item"
            :class="{ active: currentTab === 'alipay' }"
            @click="currentTab = 'alipay'"
          >
            支付宝
          </div>
        </div>
        
        <div class="qr-container">
          <img
            v-if="currentTab === 'wechat'"
            :src="wechatQR"
            alt="微信打赏"
            class="qr-code"
          />
          <img
            v-else
            :src="alipayQR"
            alt="支付宝打赏"
            class="qr-code"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ArticleReward',
  data() {
    return {
      dialogVisible: false,
      currentTab: 'wechat',
      // 更新二维码图片路径
      wechatQR: '/images/wechat_qr.jpg',
      alipayQR: '/images/alipay_qr.jpg'
    }
  },
  methods: {
    showReward() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
.reward-container {
  text-align: center;
  margin: 20px 0;
}

.reward-button {
  background-color: #409EFF;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s;
}

.reward-button:hover {
  background-color: #66b1ff;
}

.reward-content {
  padding: 20px;
}

.reward-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;
  position: relative;
}

.tab-item.active {
  color: #409EFF;
}

.tab-item.active:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #409EFF;
}

.qr-container {
  text-align: center;
}

.qr-code {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.reward-dialog {
  border-radius: 8px;
}
</style> 