<template>
  <page-container title="音乐" subtitle="音乐分享">
    <!-- 加载状态 -->
    <div v-if="loading" class="loading-state">
      <el-skeleton :rows="3" animated />
    </div>
    
    <el-empty
      v-else-if="!Array.isArray(categories) || categories.length === 0"
      description="暂无音乐分类"
    >
      <template #image>
        <el-icon class="empty-icon"><Headset /></el-icon>
      </template>
    </el-empty>
    
    <div v-else class="music-content">
      <!-- 分类标签页 -->
      <div class="tabs-header">
        <div 
          class="tab-item"
          :class="{ active: currentCategory === '' }"
          @click="handleCategorySelect('')"
        >
          全部音乐
        </div>
        <div 
          v-for="category in categories" 
          :key="category.id"
          class="tab-item"
          :class="{ active: currentCategory === category.id.toString() }"
          @click="handleCategorySelect(category.id.toString())"
        >
          {{ category.name }}
          <el-icon v-if="category.is_protected" class="lock-icon"><Lock /></el-icon>
        </div>
      </div>

      <div class="divider"></div>

      <!-- 密码验证表单 -->
      <div v-if="selectedCategory && selectedCategory.is_protected && !verifiedCategories.has(selectedCategory.id)" class="password-form">
        <el-form @submit.prevent="verifyPassword" class="verify-form">
          <div class="verify-content">
            <el-input
              v-model="categoryPassword"
              type="password"
              placeholder="请输入访问密码"
              style="width: 200px; margin-right: 10px;"
            >
              <template #prefix>
                <el-icon><Lock /></el-icon>
              </template>
            </el-input>
            <el-button type="primary" @click="verifyPassword">
              验证
            </el-button>
          </div>
        </el-form>
      </div>
      
      <!-- 音乐列表 -->
      <el-table
        v-else
        :data="musicList"
        style="width: 100%"
        :row-class-name="getRowClassName"
        @row-click="(row) => playMusic(row)"
      >
        <el-table-column width="60">
          <template #default="{ row }">
            <div class="music-cover">
              <el-image :src="row.cover || ''" fit="cover">
                <template #error>
                  <div class="image-placeholder">
                    <el-icon><Headset /></el-icon>
                  </div>
                </template>
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="title" label="歌曲">
          <template #default="{ row }">
            <div class="music-info">
              <span class="title" :class="{ active: currentMusic?.id === row.id }">{{ row.title }}</span>
              <span class="artist">{{ row.artist }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column width="100" align="right">
          <template #default="{ row }">
            <span class="duration">{{ formatTime(row.duration || 0) }}</span>
          </template>
        </el-table-column>

        <el-table-column width="60" align="center">
          <template #default="{ row }">
            <el-button 
              circle
              :type="currentMusic?.id === row.id ? 'primary' : 'default'"
              @click.stop="playMusic(row)"
              size="small"
            >
              <el-icon>
                <component :is="currentMusic?.id === row.id && isPlaying ? 'VideoPause' : 'VideoPlay'" />
              </el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加打赏区域 -->
      <div class="reward-section">
        <h3 class="reward-title">支持创作</h3>
        <p class="reward-description">如果这些音乐资源对您有帮助，欢迎打赏支持！</p>
        <article-reward />
      </div>
    </div>
  </page-container>
</template>

<script>
export default {
  name: 'MusicView',
  components: {
    Headset,
    VideoPlay,
    VideoPause,
    PageContainer,
    ArticleReward
  }
}
</script>

<script setup>
import { ref, inject, onMounted, computed, onUnmounted } from 'vue'
import { 
  Headset, VideoPlay, VideoPause, Lock
} from '@element-plus/icons-vue'
import apiService from '@/api/service'
import { ElMessage } from 'element-plus'
import PageContainer from '@/components/PageContainer.vue'
import ArticleReward from '@/components/Reward.vue'

const musicStore = inject('musicStore')
if (!musicStore) {
  console.error('musicStore not provided')
}

const loading = ref(true)
const currentPage = ref(1)
const pageSize = ref(12)
const total = ref(0)
const categories = ref(null)
const currentCategory = ref(null)
const selectedCategory = ref(null)
const categoryPassword = ref('')
const needPassword = ref(false)
const verifiedCategories = ref(new Set())

// 获取分类列表
const fetchCategories = async () => {
  try {
    loading.value = true
    const response = await apiService.getMusicCategories()
    categories.value = response.data
    
    // 设置"全部音乐"为默认激活状态
    currentCategory.value = ''
    selectedCategory.value = null
    await fetchMusicList()
  } catch (error) {
    console.error('Error fetching categories:', error)
    ElMessage.error('获取音乐分类失败')
  } finally {
    loading.value = false
  }
}

// 获取音乐列表
const fetchMusicList = async (categoryId = '') => {
  try {
    const params = {
      page: currentPage.value,
      page_size: pageSize.value
    }
    
    // 如果是获取全部音乐
    if (!categoryId) {
      params.exclude_protected = true  // 排除未验证的加密分类
      if (verifiedCategories.value.size > 0) {
        params.verified_categories = [...verifiedCategories.value]  // 包含已验证的加密分类
      }
    } else {
      params.category = categoryId
    }
    
    console.log('Fetching music with params:', params)
    const response = await apiService.getMusic(params)
    console.log('Music response:', response)
    
    if (Array.isArray(response.data)) {
      musicStore.musicList.value = response.data
      total.value = response.data.length
    } else if (response.data && Array.isArray(response.data.results)) {
      musicStore.musicList.value = response.data.results
      total.value = response.data.count || 0
    } else {
      console.error('Invalid response format:', response.data)
      musicStore.musicList.value = []
      total.value = 0
    }
  } catch (error) {
    console.error('获取音乐列表失败:', error)
    if (error.response?.status === 403) {
      ElMessage.error('没有权限访问此分类')
    } else {
      ElMessage.error('获取音乐列表失败')
    }
    musicStore.musicList.value = []
    total.value = 0
  }
}

// 处理分类选择
const handleCategorySelect = async (categoryId) => {
  console.log('Selected category:', categoryId)
  
  // 如果选择"全部音乐"
  if (!categoryId) {
    currentCategory.value = ''
    selectedCategory.value = null
    needPassword.value = false
    currentPage.value = 1
    await fetchMusicList()
    return
  }

  // 查找选中的分类
  const category = categories.value.find(c => c.id.toString() === categoryId)
  if (!category) return

  selectedCategory.value = category
  currentCategory.value = categoryId
  
  // 检查分类是否需要密码验证
  if (category.is_protected && !verifiedCategories.value.has(category.id)) {
    console.log('分类需要密码验证')
    needPassword.value = true
    musicStore.musicList.value = []
    return
  }
  
  // 如果不需要密码验证或已经验证过，直接显示音乐
  needPassword.value = false
  currentPage.value = 1
  await fetchMusicList(categoryId)
}

// 密码验证相关函数
const verifyPassword = async () => {
  if (!categoryPassword.value) {
    ElMessage.warning('请输入密码')
    return
  }

  try {
    console.log('正在验证密码...')
    const response = await apiService.verifyMusicCategoryPassword(
      selectedCategory.value.id,
      categoryPassword.value
    )
    console.log('密码验证响应:', response)
    
    if (response.data.message === '密码验证成功') {
      ElMessage.success('密码验证成功')
      verifiedCategories.value.add(selectedCategory.value.id)
      // 更新 localStorage
      localStorage.setItem('verifiedMusicCategories', 
        JSON.stringify([...verifiedCategories.value])
      )
      categoryPassword.value = ''
      
      // 获取音乐列表
      await fetchMusicList(currentCategory.value)
    } else {
      ElMessage.error('密码错误')
    }
  } catch (error) {
    console.error('Password verification error:', error)
    ElMessage.error('密码验证失败')
  }
}

// 在组件挂载时从 localStorage 恢复已验证的分类
onMounted(() => {
  const savedCategories = JSON.parse(localStorage.getItem('verifiedMusicCategories') || '[]')
  verifiedCategories.value = new Set(savedCategories)
  fetchCategories()
})

// 在组件卸载时清理
onUnmounted(() => {
  // 清理音频事件监听器
  if (musicStore.audioElement.value) {
    musicStore.audioElement.value.removeEventListener('timeupdate', () => {})
    musicStore.audioElement.value.removeEventListener('loadedmetadata', () => {})
  }
})

// 计算属性
const musicList = computed(() => musicStore.musicList.value || [])
const currentMusic = computed(() => musicStore.currentMusic.value)
const isPlaying = computed(() => musicStore.isPlaying.value)

onMounted(() => {
  // 初始化加载
  fetchCategories()
})

// 格式化时间
const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${mins}:${secs.toString().padStart(2, '0')}`
}

// 获取行样式
const getRowClassName = ({ row }) => {
  return currentMusic.value?.id === row.id ? 'playing' : ''
}

// 播放音乐
const playMusic = (music) => {
  if (!music) return
  
  try {
    // 使用全局播放器播放音乐
    musicStore.playMusic(music)
  } catch (error) {
    console.error('Error playing music:', error)
    ElMessage.error('播放失败')
  }
}
</script>

<style lang="scss" scoped>
// Mixins
@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading-state {
  padding: 40px;
}

.empty-icon {
  font-size: 48px;
  color: #909399;
}

.music-content {
  background-color: var(--el-bg-color);
  border-radius: 8px;
  overflow: hidden;
}

.tabs-header {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px 20px;
  background-color: var(--el-bg-color);
  border-radius: 8px 8px 0 0;
  
  .tab-item {
    padding: 6px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
    background-color: var(--el-color-info-light-9);
    font-size: 14px;
    
    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
    
    &.active {
      background-color: var(--el-color-primary);
      color: white;
    }
  }
}

.divider {
  height: 1px;
  background-color: var(--el-border-color-light);
  margin: 0 20px;
}

.el-table {
  margin: 20px;
  border-radius: 8px;
  overflow: hidden;
  width: calc(100% - 40px) !important;
  
  :deep(.el-table__inner-wrapper) {
    border: 1px solid var(--el-color-primary-light-7);
    border-radius: 8px;
  }
  
  .music-cover {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    
    .el-image {
      width: 100%;
      height: 100%;
    }
    
    .image-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f7fa;
      color: #909399;
    }
  }
  
  .music-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .title {
      font-size: 14px;
      color: #303133;
      
      &.active {
        color: var(--el-color-primary);
        font-weight: 500;
      }
    }
    
    .artist {
      font-size: 12px;
      color: #909399;
    }
  }
}

/* 打赏区域样式 */
.reward-section {
  margin: 40px 20px;
  text-align: center;
  padding: 30px;
  background: #f8f9fa;
  border-radius: 0;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.reward-title {
  font-size: 20px;
  color: #303133;
  margin-bottom: 12px;
}

.reward-description {
  color: #606266;
  margin-bottom: 20px;
  font-size: 14px;
}

/* 密码验证表单样式 */
.password-form {
  padding: 30px;
  margin: 20px;
  text-align: center;
  background-color: var(--el-color-primary-light-9);
  border-radius: 12px;
  border: 1px solid var(--el-color-primary-light-7);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  
  .verify-form {
    .verify-content {
      display: inline-block;
      padding: 20px 30px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }
  }
}

// 移除之前的弹窗相关样式
.password-form::before {
  display: none;
}

.lock-icon {
  margin-left: 4px;
  font-size: 14px;
  color: inherit;
  
  .active & {
    color: white;
  }
}
</style> 
